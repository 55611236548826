import BlockType from "./BlockType";
import PairwiseVersion from "./pairwise/PairwiseVersion";


export default class PairwiseBlockType extends BlockType {

  constructor(blockTypeId, name) {
    super(blockTypeId, name);
  }

  generateResponsesArray(pairwiseBlock) {

    // responsesArray is the array of resposnes that will be dumped to the Decisions XLSX spreadsheet
    const headerArray = [];
    const responsesArray = [];
    let trialCount = 0;
    const takerDict = this._generateTakerDict(pairwiseBlock);
    const stimuliArray = pairwiseBlock.orderedWyshList.getOrderedWyshes();
    const pairwiseStimuliProductIdArray = [];
    
    for (const stimulus of stimuliArray) {
      pairwiseStimuliProductIdArray.push(stimulus.product.productId);
    }

    for (const takerMid in takerDict) {
      const takerRow = [];
      takerRow.push(takerMid);

      const versionCount = stimuliArray.length / 2;
      const pairwiseVersionId = Math.floor(takerDict[takerMid].version_id * versionCount / 100) + 1
      
      takerRow.push(pairwiseVersionId);

      const decisionsByTrialSet = {};

      for (const dec of takerDict[takerMid].decisions) {

        trialCount = dec.trial > trialCount ? dec.trial : trialCount;

        if (decisionsByTrialSet[dec.trial]) {
          decisionsByTrialSet[dec.trial].push(dec);
        }
        else {
          decisionsByTrialSet[dec.trial] = [];
          decisionsByTrialSet[dec.trial].push(dec);
        }
      }
      
      for (const trial in decisionsByTrialSet) {
        takerRow.push(this._getBestIndex(decisionsByTrialSet[trial], pairwiseStimuliProductIdArray));
      }

      responsesArray.push(takerRow);
    }

    const headerRow = [
      "sys_RespNum", 
      "sys_Version_PW"
    ];

    for (let i = 0; i <= trialCount; i++) {
      headerRow.push("PW_" + (i + 1) + "_b");
    }

    headerArray.push(headerRow);

    return headerArray.concat(responsesArray);
  }

  generateUtilityScoreArray(pairwiseBlock) {

    // utilityScoresArray is the array utility scores generated for each stimuli for each respondent
    const utilityScoresArray = [];

    const takerDict = this._generateTakerDict(pairwiseBlock);
    const stimuliArray = pairwiseBlock.orderedWyshList.getOrderedWyshes();
    const pairwiseStimuliProductIdArray = [];
    
    for (const stimulus of stimuliArray) {
      pairwiseStimuliProductIdArray.push(stimulus.product.productId);
    }

    const headerRow = [
      "sys_RespNum"
    ];

    for (const s in stimuliArray) {
      const index = parseInt(s, 10) + 1;
      headerRow.push("STIM_" + index)
    }

    utilityScoresArray.push(headerRow);

    for (const takerMid in takerDict) {
      const takerRow = [];
      takerRow.push(takerMid);

      for (const productId of pairwiseStimuliProductIdArray) {
        
        for (const dec of takerDict[takerMid].decisions) {
          if (dec.productId === productId) {
            takerRow.push(dec.score);
            break;
          }
        }
      }
      
      if (takerRow.length > 1) {
        utilityScoresArray.push(takerRow);
      }
    }

    return utilityScoresArray;

  }

  getThresholdLabels() {
    return [
      {label: "Top Box", value: 70},
      {label: "Top Two Box", value: 50}
    ]
  }

  calculateTurfThreshold(block, turfThreshhold) {
    console.log(block.getLowScore() + " --> " + block.getHighScore());
    
    return block.getHighScore() * turfThreshhold / 100;
  }
  
  calculateIndividualUtilityScoresForAllTakers(stimuliArray, takerDict, calculationMethod) {
    this.generateVersions(takerDict);

    for (const takerMid in takerDict) {

      const trialSetsDecisionsMap = takerDict[takerMid].version;

      for (const stimuli of stimuliArray) {

        this._generateImportanceScore(stimuli, trialSetsDecisionsMap);
      }
    }
  }

  generateTurfReachResults(block, turfThreshhold) {

    const results = {};

    const takerMidArray = [];

    results["sys_RespNum"] = takerMidArray

    const takerDict = this._generateTakerDict(block);
    const threshold = this.calculateTurfThreshold(block, turfThreshhold);
    
    const stimuliArray = block.orderedWyshList.getOrderedWyshes();
    const stimuliProductIdArray = [];
    
    for (const stimulus of stimuliArray) {
      stimuliProductIdArray.push(stimulus.product.productId);
      results[stimulus.product.productId] = [];
    }

    for (const takerMid in takerDict) {
      const takerRow = [];
      takerMidArray.push(takerMid);

      for (const productId of stimuliProductIdArray) {
        
        for (const dec of takerDict[takerMid].decisions) {
          if (dec.productId === productId) {
            results[productId].push(dec.score >= threshold ? 1: 0);
            break;
          }
        }
      }      
    }

    return results;
  }

  // Called in Swydget toolbars
  generateIndividualUtilityScores(stimuliArray, trialSetsDecisionsMap) {

    for (const stimuli of stimuliArray) {

      this._generateImportanceScore(stimuli, trialSetsDecisionsMap);

    }
  } 

  _isVictorious(stimulus, trialSetIndex, pairwiseVersion) {

    // find the decisions involving the trial
    const trialSetDecArray = pairwiseVersion.get(trialSetIndex);

    if (trialSetDecArray && trialSetDecArray.length === 2) {
      for (const dec of trialSetDecArray) {
        if (dec.wysh.equals(stimulus) === true && dec.resultNormalized === 1) {
          return true;
        }
      }
    }

    return false;

  }

  _findOpponent(stimulus, trialSetIndex, pairwiseVersion) {

    const trialSetDecArray = pairwiseVersion.get(trialSetIndex);
    if (trialSetDecArray && trialSetDecArray.length === 2) {
      for (const dec of trialSetDecArray) {
        if (dec.wysh.equals(stimulus) === false) {
          return dec.wysh;
        }
      }
    }

    // Incomplete trialSetDecArray
    return null;
  }

  _generateImportanceScore(stimulus, pairwiseVersion) {

    let importanceScore = 0;

    // This is what I think is happening:
    // if you WON a match: 
    // - If the guy you beat WON his other match, you get 60
    // - If the guy you beat LOST his other match, you get 40
    // ELSE if you LOST:
    // - If the guy you beat WON his other match, you get 10
    // - If the guy you beat LOST his other match, you get 0
    
    // 1. find the trials for this stimulus
    const indiciesSet = this._findTrialIndices(stimulus, pairwiseVersion);


    for (const trialSetIndex of indiciesSet) {
      const opponent = this._findOpponent(stimulus, trialSetIndex, pairwiseVersion);

      if (this._isVictorious(stimulus, trialSetIndex, pairwiseVersion) === true) {
        // We WON our match YAY!!!
        // How did our opponent do?

        const opponentIndiciesSet = this._findTrialIndices(opponent, pairwiseVersion);
        for (const opponentTrialSetIndex of opponentIndiciesSet) {
          if (opponentTrialSetIndex !== trialSetIndex) {
            if (this._isVictorious(opponent, opponentTrialSetIndex, pairwiseVersion) === true) {
              // My opponent won his OTHER match
              importanceScore += 60;
            }
            else {
              // My opponent lost his OTHER match
              importanceScore += 40;
            }
          }
        }
      }
      else {
        // We LOST our match BOOOO!!!
        // How did our opponent do?
        const opponentIndiciesSet = this._findTrialIndices(opponent, pairwiseVersion);
        for (const opponentTrialSetIndex of opponentIndiciesSet) {
          if (opponentTrialSetIndex !== trialSetIndex) {
            if (this._isVictorious(opponent, opponentTrialSetIndex, pairwiseVersion) === true) {
              // My opponent won his OTHER match
              importanceScore += 20;
            }
            else {
              // My opponent lost his OTHER match
              importanceScore += 0;
            }
          }
        }
      }
    }

    const adjustedImportanceScore = (importanceScore - 20) * 1.25;

    
    for (const trialSetIndex of indiciesSet) {
      for (const dec of pairwiseVersion.get(trialSetIndex)) {
        if (dec.wysh.equals(stimulus)) {
          dec.score = adjustedImportanceScore;
        }
      }
    }
  }
}