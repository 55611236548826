import React from 'react';
import { useLocation } from 'react-router';


export default function Footer(props) {

  const location = useLocation();

  if (location.pathname.startsWith("/swydget") === true) {
    return (
      <footer></footer>
    );
  }
  else {
    return (
      <footer className='footerStyles'>
        <div>Copyright &copy; Swytchback, Inc. since 2014</div>
      </footer>
    );
  }
};