import React, { useState, useEffect, useCallback } from 'react';
import { Typography, FormControl, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import classes from '../../../css/modules/MultipleChoiceToolbar.module.css';
import "../../../css/multipleChoiceStyles.css";
import ProductStyles from '../../../classes/ProductStyles';
import { ArrowCircleDown, ArrowCircleRight, ArrowCircleUp } from '@mui/icons-material';
import CardEditor from '../../../components/admin/CardEditor';


export default function MultipleChoiceToolbar(props) {

  const [selectedChoices, setSelectedChoices] = useState(new Set());
  const [displayNextButton, setDisplayNextButton] = useState(false);
  const [showChoicesModal, setShowChoicesModal] = useState(true);
  const [choicesExpanded, setChoicesExpanded] = useState(false);
  const [allChoicesViewed, setAllChoicesViewed] = useState(false);
  const [choicesDivClasses, setChoicesDivClasses] = useState("choicesTrayStyle roundedCornersStyle enable-scrolling collapsed");

  const scrollCallback = (entries) => {

    if (entries[0].isIntersecting === true) {
      setAllChoicesViewed(true);
    }
  }


  useEffect(() => {

    if (props.wysh && props.wysh.mustViewAll === true) {
      var scrollObserver = new IntersectionObserver(scrollCallback, { root: document.querySelector('#mcChoicesDiv'), threshold: [0] });
      scrollObserver.observe(document.querySelector("#mcScrollBottomDiv"));

      return () => {
        scrollObserver.unobserve(document.querySelector("#mcScrollBottomDiv"));
      }
    }
    else {
      setAllChoicesViewed(true);
    }
  });

  const submitFunction = props.submitFunction;
  var trayControlGlyphStyle = selectedChoices.size > 0 ? "mcGlyphStyleGray" : "mcGlyphStyle";
  var trayUpControlPulseStyle = selectedChoices.size > 0 ? "circle" : "circle pulse";


  const handleChoiceSelected = (event, choice) => {

    if (choice.resultNormalized === 99.0) {
      //remove all others
      const newSelectedChoices = new Set();
      newSelectedChoices.add(choice);
      setSelectedChoices(newSelectedChoices);
    }
    else {
      const newSelectedChoices = props.singleChoice ? new Set() : new Set(selectedChoices);

      // Remove none of the above if it has been selected prior
      newSelectedChoices.forEach((ch) => {
        if (ch.resultNormalized === 99.0) {
          newSelectedChoices.delete(ch);
        }
      });

      if (selectedChoices.has(choice)) {
        newSelectedChoices.delete(choice);
        setSelectedChoices(newSelectedChoices);
      }
      else {
        if (props.wysh && (props.wysh.mcOptionsLimit === 0 || selectedChoices.size < props.wysh.mcOptionsLimit)) {
          newSelectedChoices.add(choice);
          setSelectedChoices(newSelectedChoices);
        }


      }
    }


  };

  const handleCivicDone = useCallback(

    (event) => {
      setDisplayNextButton(true);
      if (props.onHide) {
        props.onHide();
      }
    }, []
  );

  const toggleChoicesHeight = () => {

    switch (choicesExpanded) {
      case true:
        setChoicesDivClasses("choicesTrayStyle roundedCornersStyle enable-scrolling collapsed")
        break;
      case false:
        setChoicesDivClasses("choicesTrayStyle roundedCornersStyle enable-scrolling expanded")
        break;
      default:
    }

    setChoicesExpanded(!choicesExpanded);
  }

  const resetChoicesHeight = () => {
    document.getElementById("mcChoicesDiv").scrollTo(0, 0);
    setChoicesDivClasses("choicesTrayStyle roundedCornersStyle enable-scrolling collapsed");
    setChoicesExpanded(false);
  }

  const getSpecialOption = (resultNormalized) => {
    for (var i = 0; i < props.choices.length; i++) {
      if (props.choices[i].resultNormalized === resultNormalized) {
        return props.choices[i];
      }
    }
  }

  const generateChoicesWithoutNoneOfTheAboveOrOther = () => {
    var choices = [];
    for (var i = 0; i < props.choices.length; i++) {
      if (props.choices[i].resultNormalized !== 99.0 && props.choices[i].resultNormalized !== 98.0) {
        choices.push(props.choices[i]);
      }
    }

    return choices;
  }

  const noneOfTheAboveOption = getSpecialOption(99.0);
  const otherOption = getSpecialOption(98.0);
  var choicesWithoutNoneOfTheAboveOrOther = generateChoicesWithoutNoneOfTheAboveOrOther();

  return (
    <div className="containerDivStyle" style={{width: props.width}}>
      <FormControl component="fieldset" className="formControl">
        <FormGroup>
          <div className="contentDivStyle">
            <div id="choicesContainerDiv" className="choicesContainerStyle">
              <div
                id="choicesDiv"
                className={choicesDivClasses}
                >
                <div id="mcChoicesDiv" className="choicesDivStyle">
                  <div style={{fontSize: ProductStyles.determineFontSizeBasedOnLength(props.instruction)}}className="instructionDivStyle">{props.instruction}</div>
                { choicesWithoutNoneOfTheAboveOrOther.map((ch, idx) => {
                  return (
                    <div key={"choice-" + idx} className="choiceRowStyle">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedChoices.has(ch)}
                            onChange={(e) => {handleChoiceSelected(e, ch);}} name={ch.resultLiteral}
                            inputProps={{ 'aria-label': 'indeterminate checkbox' }}
                            style={{color: '#ffffff'}}
                          />
                        }
                        label={<Typography className={`${classes.formControlLabel}`}>{CardEditor.generateStylizedDiv(ch.resultLiteral, "choice-idx-div", {})}</Typography>}
                      />
                    </div>
                  )
                })}
                { otherOption &&
                  <div key={"choice-" + choicesWithoutNoneOfTheAboveOrOther.length} className="choiceRowStyle">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedChoices.has(otherOption)}
                          onChange={(e) => {handleChoiceSelected(e, otherOption);}} name={otherOption.resultLiteral}
                          inputProps={{ 'aria-label': 'indeterminate checkbox' }}
                          style={{color: '#ffffff'}}
                        />
                      }
                      label={<Typography className={`${classes.formControlLabel}`}>{otherOption.resultLiteral}</Typography>}
                    />
                  </div>
                }
                { noneOfTheAboveOption &&
                  <div key={"choice-" + choicesWithoutNoneOfTheAboveOrOther.length + 1} className="choiceRowStyle">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedChoices.has(noneOfTheAboveOption)}
                          onChange={(e) => {handleChoiceSelected(e, noneOfTheAboveOption);}} name={noneOfTheAboveOption.resultLiteral}
                          inputProps={{ 'aria-label': 'indeterminate checkbox' }}
                          style={{color: '#ffffff'}}
                        />
                      }
                      label={<Typography className={`${classes.formControlLabel}`}>{noneOfTheAboveOption.resultLiteral}</Typography>}
                    />
                  </div>
                }
                  <div
                    id="mcScrollBottomDiv"
                    style={{minHeight: 1, minWidth: 50}}>
                  </div>
                </div>
              </div>
              <div className="mcChoicesHeader">

                { choicesExpanded ?
                  <div className="circle">
                    <ArrowCircleDown
                      style={{fontSize: 34}}
                      className={trayControlGlyphStyle}
                      onClick={() => {toggleChoicesHeight();}}
                    />
                  </div>
                  :
                  <div className={trayUpControlPulseStyle}>
                    <ArrowCircleUp
                      style={{fontSize: 34}}
                      className={trayControlGlyphStyle}
                      onClick={() => {toggleChoicesHeight();}}
                    />
                  </div>
                }
              </div>
            </div>
            <div className="mcSubmitButtonDiv">
            {selectedChoices.size > 0 && allChoicesViewed &&
              <div className="circle pulse">
                <ArrowCircleRight
                  style={{fontSize: 34}}
                  className="mcGlyphStyle"
                  onClick={() => {
                    submitFunction(selectedChoices);
                    setSelectedChoices(new Set());
                    resetChoicesHeight();
                    setShowChoicesModal(true);
                    setAllChoicesViewed(false);
                  }}
                />
              </div>
            }
            </div>
          </div>
        </FormGroup>
      </FormControl>
    </div>
  );
}
