import React from "react";
import { Button } from '@mui/material';
import classes from '../../../css/modules/AdminControls.module.css';
import '../../../css/swytchbackModal.css';
import '../../../css/swytchbackStyles.css';
import SwytchbackSelect from "../controls/SwytchbackSelect";
import SwytchbackFrameButton from "../controls/SwytchbackFrameButton";


const BlockOptionsFormWidget = ({
  questionType, 
  wyshOptions, 
  getOptionsCount, 
  handleNumberOfOptionsChange,
  generateSelectOptions,
  mcOptionsLimit,
  handleMcOptionsLimitChange,
  randomizeWyshOptions,
  handleRandomizeWyshOptions,
  mcIncludeNoneOfTheAbove,
  handleMcIncludeNoneOfTheAbove,
  mcIncludeOther,
  handleMcIncludeOther,
  mustViewAll,
  handleMustViewAll,
  generateChoiceFields,
  binaryIconSetID,
  handleBinaryIconSetIdChange,
  generateMenuItems
}) => {

  const generateBlockOptionsForm = () => {

    if (questionType === "multiple-choice" ||
        questionType === "single-select") {
      if (wyshOptions.length === 0) {
        return (
          <div className="rowContainerSpaced marginBottom fullWidth">
            <div className='title'>
              Response Options:
            </div>
            <div style={{width: '15%'}} className='content'>
              <SwytchbackSelect
                className="swytchbackSelect fullBorder fullWidth"
                disabled={false}
                inputProps={{ IconComponent: () => null }}
                id="font-family-select"
                value={getOptionsCount()}
                onChange={(evt) => handleNumberOfOptionsChange(evt)}>
                {generateSelectOptions(1, 100)}
              </SwytchbackSelect>
            </div>
          </div>    
        )
      }
      else {
        return (
          <div className="swytchbackAdminControl">
            <div className="rowContainerSpaced marginBottom fullWidth">
              <div className='title'>
                Response Options:
              </div>
              <div style={{width: '15%'}} className='content'>
                <SwytchbackSelect
                  className="swytchbackSelect fullBorder fullWidth"
                  disabled={false}
                  inputProps={{ IconComponent: () => null }}
                  id="font-family-select"
                  value={getOptionsCount()}
                  onChange={(evt) => handleNumberOfOptionsChange(evt)}>
                  {generateSelectOptions(1, 100)}
                </SwytchbackSelect>
              </div>
            </div>    
            { questionType === "multiple-choice" &&
            <div className="rowContainerSpaced marginBottom fullWidth">
              <div className='title'>
                Set Limit:
              </div>
              <div style={{width: '15%'}} className='content'>
                <SwytchbackSelect
                  className="swytchbackSelect fullBorder fullWidth"
                  disabled={false}
                  inputProps={{ IconComponent: () => null }}
                  id="font-family-select"
                  value={mcOptionsLimit}
                  onChange={(evt) => handleMcOptionsLimitChange(evt)}>
                  {generateSelectOptions(1, getOptionsCount())}
                </SwytchbackSelect>
              </div>
            </div>    
            }
            <div className="rowContainerSpaced marginBottom fullWidth">
              <SwytchbackFrameButton
                className={randomizeWyshOptions ? "swytchbackButton swytchbackButtonActive" : "swytchbackButton"} 
                style={{width: '24%'}}
                onClick={() => handleRandomizeWyshOptions()}>
                Randomize Options
              </SwytchbackFrameButton>
              <SwytchbackFrameButton
                className={mcIncludeNoneOfTheAbove ? "swytchbackButton swytchbackButtonActive" : "swytchbackButton"} 
                style={{width: '24%'}}
                onClick={() => handleMcIncludeNoneOfTheAbove()}>
                None of the Above
              </SwytchbackFrameButton>
              <SwytchbackFrameButton
                className={mcIncludeOther ? "swytchbackButton swytchbackButtonActive" : "swytchbackButton"} 
                style={{width: '24%'}}
                onClick={() => handleMcIncludeOther()}>
                Other
              </SwytchbackFrameButton>
              <SwytchbackFrameButton
                className={mustViewAll ? "swytchbackButton swytchbackButtonActive" : "swytchbackButton"} 
                style={{width: '24%'}}
                onClick={() => handleMustViewAll()}>
                Must View All Options
              </SwytchbackFrameButton>
            </div>
            <div className="columnContainer fullWidth">
              {generateChoiceFields()}
            </div>
          </div>
        );
      }
    }
    else if (questionType === "scalar" || questionType === "nps" || questionType === "csat") {
      if (wyshOptions === 0) {
        return (
          <div className="swytchbackAdminControl">
            <div className="rowContainerSpaced marginBottom fullWidth">
              <div className='title'>
                Response Options:
              </div>
              <div style={{width: '15%'}} className='content'>
                <SwytchbackSelect
                  className="swytchbackSelect fullBorder fullWidth"
                  disabled={false}
                  inputProps={{ IconComponent: () => null }}
                  id="font-family-select"
                  value={getOptionsCount()}
                  onChange={(evt) => handleNumberOfOptionsChange(evt)}>
                  {generateSelectOptions(1, 100)}
                </SwytchbackSelect>
              </div>
            </div>    
          </div>  
        )
      }
      else {
        return (
          <div className="swytchbackAdminControl">
            <div className="rowContainerSpaced marginBottom fullWidth">
              <div className='title'>
                Response Options:
              </div>
              <div style={{width: '15%'}} className='content'>
                <SwytchbackSelect
                  className="swytchbackSelect fullBorder fullWidth"
                  disabled={false}
                  inputProps={{ IconComponent: () => null }}
                  id="font-family-select"
                  value={wyshOptions.length}
                  onChange={(evt) => handleNumberOfOptionsChange(evt)}>
                  {generateSelectOptions(1, 100)}
                </SwytchbackSelect>
              </div>
            </div>    
            <div style={{width: '94%'}} className="columnContainer">
              {generateChoiceFields()}
            </div>
          </div>

        );
      }
    }
    else if (questionType === "binary") {
      return (
        <div className="rowContainerSpaced marginBottom fullWidth">
          <div className='title'>
          Select Buttons:
          </div>
          <div style={{width: '40%'}} className='content'>
            <SwytchbackSelect
              className="swytchbackSelect fullBorder fullWidth"
              disabled={false}
              inputProps={{ IconComponent: () => null }}
              id="font-family-select"
              value={binaryIconSetID}
              onChange={(evt) => handleBinaryIconSetIdChange(evt)}>
              {generateMenuItems()}
            </SwytchbackSelect>
          </div>
        </div>    
      );
    }
    else {
      return (
        <div className="swytchbackAdminControl">
          
        </div>
      )
    }
  }

  return (
    <div className="widget fullWidth">
      <div className='content'>
        <div className="swytchbackAdminControl">
          {generateBlockOptionsForm()}
        </div>    
      </div>
    </div>      
  );
}

export default BlockOptionsFormWidget;