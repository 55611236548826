

export default class ProductStyles
{
  constructor() {
    this.textShadow = "none";
    this.justification = "center";
    this.verticalAlign = "center";
    this.fontSize = "large"; // small, medium, large
    this.textColor = "#ffffff";
    this.fontFamily = "helvetica";
    this.isBold = true;
    this.isItalicized = false;
    this.objectFit = "cover";
  }

  initFromJson(json) {

    if (json.textShadow) {
      this.textShadow = json.textShadow;
    }

    if (json.justification) {
      this.justification = json.justification;
    }

    if (json.verticalAlign) {
      this.verticalAlign = json.verticalAlign;
    }

    if (json.fontSize) {
      this.fontSize = json.fontSize;
    }

    if (json.textColor) {
      this.textColor = json.textColor;
    }

    if (json.fontFamily) {
      this.fontFamily = json.fontFamily;
    }

    if ('isBold' in json) {
      this.isBold = json.isBold;
    }

    if ('isItalicized' in json) {
      this.isItalicized = json.isItalicized;
    }

    if (json.objectFit) {
      this.objectFit = json.objectFit;
    }
  }

  isJustificationActive(value) {
    if (value && value === this.justification) {
      return true;
    }

    return false;
  }

  isObjectFitActive(value) {
    if (value && value === this.objectFit) {
      return true;
    }

    return false;
  }

  isVerticalAlignActive(value) {
    if (value && value === this.verticalAlign) {
      return true;
    }

    return false;
  }

  isFontSizeActive(value) {
    return value && value === this.fontSize;
  }

  isShadowEnabled() {
    if (this.textShadow === "none") {
      return false;
    }
    else {
      return true;
    }
  }

  getFontFamily() {
    var fontFamily = '"Helvetica Neue", Helvetica, Arial, sans-serif';

    switch (this.fontFamily) {
      case "arial":
        fontFamily = "Arial";
        break;
      case "times":
        fontFamily = "Times";
        break;
      case "courier":
        fontFamily = "courier";
        break;
      case "helvetica":
        fontFamily = '"Helvetica Neue", Helvetica, Arial, sans-serif';
        break;
      case "sans-serif":
        fontFamily = "sans-serif";
        break;


      default:
        fontFamily = '"Helvetica Neue", Helvetica, Arial, sans-serif';
    }

    return fontFamily;
  }

  getFontWeight() {
    return this.isBold ? 800 : 500;
  }

  getFontStyle() {
    return this.isItalicized ? "italic" : "normal";
  }

  getTextShadow() {

    var r = 255;
    var g = 255;
    var b = 255;
    var alpha = 1.0;

    switch (this.textShadow) {
      case "none":
        r = 0;
        g = 0;
        b = 0;
        alpha = 0.0;
        break;
      case "light":
        r = 200;
        g = 200;
        b = 215;
        alpha = 1.0;
        break;
      case "dark":
        r = 0;
        g = 0;
        b = 0;
        alpha = 1.0;
        break;
      default:
        r = 0;
        g = 0;
        b = 0;
        alpha = 0.0;
    }

    return "0.0vh 0.0vh 0.5vh rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  }

  determineFontSize(cardHeight) {

    const sizeFactor = cardHeight / 30;

    const smallSize = sizeFactor;
    const mediumSize = sizeFactor * 1.333333;
    const largeSize = sizeFactor * 1.666667;
    const largerSize = sizeFactor * 2.25;
    const largestSize = sizeFactor * 2.75;

    switch (this.fontSize) {
      case "small":
        return smallSize + "vh";
      case "medium":
        return mediumSize + "vh";
      case "large":
        return largeSize + "vh"
      case "larger":
        return largerSize + "vh";
      case "largest":
        return largestSize + "vh";
      default:
        return smallSize + "vh";
    }
  }

  determineLineHeight(cardHeight) {

    const sizeFactor = cardHeight / 25;

    const smallSize = sizeFactor;
    const mediumSize = sizeFactor * 1.333333;
    const largeSize = sizeFactor * 1.666667;
    const largerSize = sizeFactor * 2.25;
    const largestSize = sizeFactor * 2.75;
    

    switch (this.fontSize) {
      case "small":
        return smallSize + "vh";
      case "medium":
        return mediumSize + "vh";
      case "large":
        return largeSize + "vh"
      case "larger":
        return largerSize + "vh";
      case "largest":
        return largestSize + "vh";
      default:
        return smallSize + "vh";
    }
  }

  static determineFontSizeBasedOnLength(str){

    if (str) {
      if (str.length < 100) {
        return 18
      }
      else if (str.length < 150) {
        return 14
      }
      else if (str.length < 256) {
        return 12
      }
      else {
        return 8
      }

    }
    
    return 18;
  
  }

  determinePadding(cardHeight) {
    let sizeFactor = (cardHeight) ? cardHeight / 20 : 1.0;
    return sizeFactor + "vh";
  }

  determineParagraphSpacing(cardHeight) {
    let sizeFactor = (cardHeight) ? cardHeight / 20 : 1.0;
    return sizeFactor + "vh";
  }

  static createFromJson(productJson) {
    var ps = new ProductStyles();
    ps.initFromJson(productJson);
    return ps;
  }
}
