import React, { useState } from "react";
import DOMPurify from "dompurify";
import '../../css/swytchbackTable.css';

export default function Card(props) {

  const product = props.product;
  const cardHeight = props.cardHeight;
  const wysh = props.wysh;
  let cardWidth = cardHeight * (2.5/3.5); // standard card width
  let cardHeightStyle = cardHeight + 'vh';
  let cardWidthStyle = cardWidth + 'vh';

  let textBoxContainerStyle = {
    margin: 0,
    padding: product.styles.determinePadding(cardHeight),
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: product.styles.verticalAlign,
    flexDirection: 'column',
    textAlign: product.styles.justification,
    top: 0,
    left: 0,
    width: cardWidthStyle,
    height: cardHeightStyle,
    color: product.styles.textColor,
    fontSize: product.styles.determineFontSize(cardHeight),
    fontFamily: product.styles.getFontFamily(),
    fontWeight: product.styles.getFontWeight(),
    fontStyle: product.styles.getFontStyle(),
    lineHeight: product.styles.determineLineHeight(cardHeight),
  };

  let dropzoneStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: cardWidthStyle,
    height: cardHeightStyle,
    textColorColor: '#ffffff',
    color: '#000000',
    borderRadius: '5%',
    padding:0,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    // WebkitBoxShadow: '1px 1px 3px 3px #ccc',  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    // MozBoxShadow: '1px 1px 3px 3px #ccc',  /* Firefox 3.5 - 3.6 */
    // boxShadow: '1px 1px 3px 3px #ccc',  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
  };

  let imagePreviewStyle = {
    width: 'auto',
    height: cardHeightStyle,
    backgroundColor: '#ffffff',
    color: '#000000',
    borderRadius: '5%',
    padding:0,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    overflow: 'hidden'
  };

  let imageStyle = {
    width: cardWidthStyle,
    height: cardHeightStyle,
    objectFit: product.styles.objectFit
  };


  var distanceStyle = "1vh";

  let paragraphDivs = product.toParagraphsArray().map((phrase, idx) => {

    const sanitizedPhrase = DOMPurify.sanitize(phrase, {
      ALLOWED_TAGS: ["b", "i"] // Only allow these tags
    });

    if (idx === 0) {
      return (
        <div key={idx} dangerouslySetInnerHTML={{ __html: sanitizedPhrase }}/>
      )
    }
    else {
      return (
        <div style={{marginTop: product.styles.determineParagraphSpacing(cardHeight)}} key={idx} dangerouslySetInnerHTML={{ __html: sanitizedPhrase }}/>
      )
    }
  });

  return (
    <div style={dropzoneStyle}>
      <div style={imagePreviewStyle} className="card-container">
        <div style={{ position: 'relative',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      flexDirection: 'column',
                      height: cardHeightStyle,
                      width: cardWidthStyle,
                      background: 'linear-gradient(#0167EC, #00B6F9)',
                      overflow: 'hidden' }}>
          { product.getSecureImageUrl(wysh) &&
          <img src={product.getSecureImageUrl(wysh)} style={imageStyle} alt='Swytchback image'/>
          }
          <div style={textBoxContainerStyle}>
            <div style={{width: '100%', textShadow: product.styles.getTextShadow()}}>
              {paragraphDivs}
            </div>
          </div>
        </div>
      </div>
    </div>
  );


}
