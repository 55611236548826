import React from "react";
import { MenuItem } from '@mui/material';
import classes from '../../../css/modules/AdminControls.module.css';
import '../../../css/swytchbackModal.css';
import '../../../css/swytchbackStyles.css';
import SwytchbackSelect from "../controls/SwytchbackSelect";


const StandardQuestionTypeSelector = ({readOnly, questionType, questionDescription, callback}) => {

  return (
    <div className="widget fullWidth">
      <div className='title marginBottom'>
        Question Type:
      </div>
      <div className='content'>
        <div className="swytchbackAdminControl">
          <div className="rowContainer">
            <SwytchbackSelect
              disabled={readOnly}
              inputProps={{ IconComponent: () => null }}
              id="question-type-select"
              value={questionType}
              onChange={(evt) => callback(evt)}
            >
              <MenuItem key={"select"} className={classes.swytchbackMenuItem} value={"select"}>select</MenuItem>
              <MenuItem key={"binary"} className={classes.swytchbackMenuItem} value={"binary"}>Binary (Yes/No)</MenuItem>
              <MenuItem key={"scalar"} className={classes.swytchbackMenuItem} value={"scalar"}>Scalar</MenuItem>
              <MenuItem key={"multiple-choice"} className={classes.swytchbackMenuItem} value={"multiple-choice"}>Multiple Choice</MenuItem>
              <MenuItem key={"single-select"} className={classes.swytchbackMenuItem} value={"single-select"}>Single Select</MenuItem>
              <MenuItem key={"nps"} className={classes.swytchbackMenuItem} value={"nps"}>Net Promoter Score (NPS)</MenuItem>
              <MenuItem key={"csat"} className={classes.swytchbackMenuItem} value={"csat"}>Customer Satisfaction (CSAT)</MenuItem>
              <MenuItem key={"next"} className={classes.swytchbackMenuItem} value={"next"}>Next</MenuItem>
              <MenuItem key={"free-response-only"} className={classes.swytchbackMenuItem} value={"free-response-only"}>Verbatim</MenuItem>
            </SwytchbackSelect>
            <div className="swytchbackInstruction">{questionDescription}</div>
          </div>
        </div>    
      </div>
    </div>      
  );
}

export default StandardQuestionTypeSelector;