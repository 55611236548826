import React, { useState } from 'react';
import { Paper, Popover } from '@mui/material';
import { TextField } from '@mui/material';
import { Table, Button } from 'react-bootstrap';
import SwytchbackButton from '../controls/SwytchbackButton';
import classes from '../../../css/modules/AccountManager.module.css';
import AuthService from '../../../services/AuthService';
import SwytchbackTextField from '../controls/SwytchbackTextField';
import SwytchbackFrameButton from '../controls/SwytchbackFrameButton';

const SegmentsAdmin = ({ user }) => {
  const [segmentName, setSegmentName] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [showAddSegmentPopover, setShowAddSegmentPopover] = useState(false);

  const addSegment = () => {
    AuthService.addSegment(user, segmentName);
    setShowAddSegmentPopover(false);
    setAnchorEl(null);
    setSegmentName('');
  };

  const deleteSegment = (evt, user, segment) => {
    AuthService.deleteSegment(user, segment);
  };

  const generateSegmentRows = (user) => {
    let tdLeftStyle = { textAlign: 'center', verticalAlign: 'middle', width: 100 };
    let tdStyle = { textAlign: 'center', verticalAlign: 'middle' };
    let tdRightStyle = { textAlign: 'center', verticalAlign: 'middle', width: 150 };

    const rows = [];
    if (user) {
      const keys = Object.keys(user.segmentBucket.segments);
      keys.forEach((key) => {
        const segment = user.segmentBucket.segments[key];
        rows.push(
          <tr key={`segment-${key}`}>
            <td style={tdLeftStyle}>{segment.ordinal}</td>
            <td style={tdStyle}>{segment.segment_name}</td>
            <td style={tdRightStyle}>
              <Button onClick={(evt) => deleteSegment(evt, user, segment)}>Remove</Button>
            </td>
          </tr>
        );
      });
    }
    return rows;
  };

  let thLeftStyle = { textAlign: 'center', verticalAlign: 'middle', width: 100, borderBottom: '1px solid black' };
  let thStyle = { textAlign: 'center', verticalAlign: 'middle', borderBottom: '1px solid black' };
  let thRightStyle = { textAlign: 'center', verticalAlign: 'middle', width: 150, borderBottom: '1px solid black' };

  
return (
    <div style={{ width: '100%' }}>
      <div style={{ width: '100%' }}>
        <Table responsive>
          <thead>
            <tr>
              <th style={thLeftStyle}>Segment ID</th>
              <th style={thStyle}>Segment Name</th>
              <th style={thRightStyle}></th>
            </tr>
          </thead>
          <tbody>{generateSegmentRows(user)}</tbody>
        </Table>
      </div>
      <div>
        

        <SwytchbackButton
          onClick={
            (evt) => {
              setAnchorEl(evt.currentTarget);
              setShowAddSegmentPopover(true);
            }
          }
        >
          + Add Segment
        </SwytchbackButton>
        <Popover
          id="popover-add-segments"
          open={showAddSegmentPopover}
          anchorEl={anchorEl}
          onClose={() => {
            setAnchorEl(null);
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          slotProps={{
            paper: {
              style: {
                borderRadius: 5
              }
            }
          }}
        >
          <Paper className={`${classes.paperStyle}`}>
            <div className={`${classes.title}`}>Add Segment</div>
            <div className={`${classes.buttonContainerStyle}`}>
              <SwytchbackTextField
                id="addSegmentNameTF"
                style={{width: '100%'}}
                label="Enter Segment Name"
                variant="outlined"
                value={segmentName}
                onChange={(e) => setSegmentName(e.target.value)}
              />
            </div>
            <div className={`${classes.buttonContainerStyle}`}>
              <SwytchbackFrameButton style={{width: '100%'}} onClick={(evt) => {
                addSegment();
              }}>
                Add  
              </SwytchbackFrameButton>
            </div>
          </Paper>
        </Popover>  

      </div>
    </div>
  );
};

export default SegmentsAdmin;
