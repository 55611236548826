import React from "react";
import '../../../css/swytchbackModal.css';
import '../../../css/swytchbackStyles.css';
import SwytchbackSelect from "../controls/SwytchbackSelect";
import SwytchbackFrameButton from "../controls/SwytchbackFrameButton";


const BatchOptionsWidget = ({
  readOnly,
  block,
  handleIssueSubGroupClicked, 
  handleSubGroupCountChanged,
  generateSelectOptions,
  handleLimitFreeResponseClicked,
  handleLimitFreeResponseCountChanged,
}) => {

  

  return (
    <div className="widget fullWidth">
      <div className='title marginBottom'>
        Batch Stimuli:
      </div>
      <div className='content rowContainerSpaced marginBottom'>
        <div className="instruction">
          Batch
        </div>
        <div style={{width: '25%'}} className="rowContainer">
          <SwytchbackFrameButton
            disabled={readOnly || !block.randomize}
            className={block.issueSubGroups === true ? "swytchbackButton swytchbackButtonActive left" : "swytchbackButton left"} 
            style={{width: '50%'}}
            onClick={(e) => handleIssueSubGroupClicked(true)}>
            Yes
          </SwytchbackFrameButton>
          <SwytchbackFrameButton 
            disabled={readOnly || !block.randomize}
            className={block.issueSubGroups === false ? "swytchbackButton swytchbackButtonActive right" : "swytchbackButton right"} 
            style={{width: '50%', borderLeft: '1px solid white'}}
            onClick={(e) => handleIssueSubGroupClicked(false)}>
            No
          </SwytchbackFrameButton>
        </div>
      </div>  
      <div className='content rowContainerSpaced marginBottom'>
        <div className="instruction">
          Count
        </div>  
        <SwytchbackSelect
          className="swytchbackSelect fullBorder"
          disabled={readOnly || !block.randomize || !block.issueSubGroups}
          inputProps={{ IconComponent: () => null }}
          value={block.subGroupCount}
          id="set-batch-count-select"
          onChange={(evt) => handleSubGroupCountChanged(evt)}
        >
          {generateSelectOptions(0, block.orderedWyshList.wyshes.length)}
        </SwytchbackSelect>
      </div>
      <div className='content rowContainerSpaced marginBottom'>
        <div className="instruction">
          Limit Free Response
        </div>  
        <div style={{width: '25%'}} className="rowContainer">
          <SwytchbackFrameButton
            disabled={readOnly || !block.randomize}
            className={block.limitFreeResponse === true ? "swytchbackButton swytchbackButtonActive left" : "swytchbackButton left"} 
            style={{width: '50%'}}
            onClick={(e) => handleLimitFreeResponseClicked(true)}>
            Yes
          </SwytchbackFrameButton>
          <SwytchbackFrameButton 
            disabled={readOnly || !block.randomize}
            className={block.limitFreeResponse === false ? "swytchbackButton swytchbackButtonActive right" : "swytchbackButton right"} 
            style={{width: '50%', borderLeft: '1px solid white'}}
            onClick={(e) => handleLimitFreeResponseClicked(false)}>
            No
          </SwytchbackFrameButton>
        </div>
      </div>  
      <div className='content rowContainerSpaced marginBottom'>
        <div className="instruction">
          Count
        </div>  
        <SwytchbackSelect
          className="swytchbackSelect fullBorder"
          disabled={readOnly || !block.randomize || !block.limitFreeResponse}
          inputProps={{ IconComponent: () => null }}
          value={block.limitFreeResponseCount}
          id="set-limit-verbatim-count-select"
          onChange={(evt) => handleLimitFreeResponseCountChanged(evt)}
        >
          {generateSelectOptions(0, block.issueSubGroups === true ? block.subGroupCount : block.orderedWyshList.wyshes.length)}
        </SwytchbackSelect>
      </div>
    </div>
  );
}

export default BatchOptionsWidget;