import React, { useState, useEffect } from "react";
import { MenuItem } from '@mui/material';
import classes from '../../../css/modules/AdminControls.module.css';
import WyshOption from "../../../classes/WyshOption";
import RandomizeWidget from "./RandomizeWidget";
import PromptWidget from "./PromptWidget";
import BinaryIconSet from "../../../classes/BinaryIconSet";
import SwytchbackTextField from "../controls/SwytchbackTextField";
import SequentialMonadicQuestionTypeSelector from "./SequentialMonadicQuestionTypeSelector";
import BlockOptionsFormWidget from "./BlockOptionsFormWidget";
import BatchOptionsWidget from "./BatchOptionsWidget";
import SwytchbackFrameButton from "../controls/SwytchbackFrameButton";
import VerbatimOptionsWidget from "./VerbatimOptionsWidget";


export default function SequentialMonadicConfigureComponent(props) {

  const [localBlockWysh, setLocalBlockWysh] = useState(props.blockWysh);
  const [updatedBlockAttributesDict, setUpdatedBlockAttributesDict] = useState({});
  
  useEffect(() => {

  }, [props.localBlockWysh]);
  
  
  const handleDefaultPromptChange = (evt) => {
    const bw = localBlockWysh;
    bw.caption = evt.target.value;
    updatedBlockAttributesDict["defaultPrompt"] = evt.target.value;
    setUpdatedBlockAttributesDict(updatedBlockAttributesDict);
    setLocalBlockWysh(bw);
    props.updateBlockCallback(bw, updatedBlockAttributesDict);
  }

  const handleBlockNameChange = (evt) => {
    const bw = localBlockWysh;
    bw.product.name = evt.target.value;
    setLocalBlockWysh(bw);
    props.updateBlockCallback(bw, updatedBlockAttributesDict);
  }

  const handleQuestionTypeChange = (evt) => {
    const bw = localBlockWysh;
    bw.questionType = evt.target.value;
    updatedBlockAttributesDict["questionType"] = evt.target.value;

    if (bw.questionType !== "multiple-choice") {
      
      const scalarWyshOptions = bw.wyshOptions.filter((wo) => {
        return wo.resultNormalized < 98;
      });

      bw.wyshOptions = scalarWyshOptions;
      bw.mcIncludeNoneOfTheAbove = false;
      bw.mcIncludeOther = false;
      bw.randomizeWyshOptions = false;
      bw.mustViewAll = false;

      updatedBlockAttributesDict["mcIncludeNoneOfTheAbove"] = bw.mcIncludeNoneOfTheAbove;
      updatedBlockAttributesDict["mcIncludeOther"] = bw.mcIncludeOther;
      updatedBlockAttributesDict["randomizeWyshOptions"] = bw.randomizeWyshOptions;
      updatedBlockAttributesDict["mustViewAll"] = bw.mustViewAll;
    }

    if (!("wyshOptions" in updatedBlockAttributesDict)) {
      updatedBlockAttributesDict["wyshOptions"] = bw.wyshOptions
    }

    setUpdatedBlockAttributesDict(updatedBlockAttributesDict);
    setLocalBlockWysh(bw);
    props.updateBlockCallback(bw, updatedBlockAttributesDict);
  }

  const generateSelectOptions = (startingIdx, count) => {

    var optionsArray = [];

    for (var i = startingIdx; i < count; i++) {
      optionsArray.push(<MenuItem key={"option-" + i} className={classes.swytchbackMenuItem} value={(i + 1).toString()}>{i + 1}</MenuItem>)
    }
    return optionsArray;
  }

  const getOptionsCount = () => {

    const bw = localBlockWysh;
    let currentOptions = bw.wyshOptions;

    var count = currentOptions.length;

    for (var i = 0; i < currentOptions.length; i++) {
      if (currentOptions[i].resultNormalized && (currentOptions[i].resultNormalized === 99.0 || currentOptions[i].resultNormalized === 98.0)) {
        count = count - 1;
      }
    }

    return count;
  }

  const handleNumberOfOptionsChange = (evt) => {

    var bw = localBlockWysh
    let currentOptions = bw.wyshOptions;

    let numberOfOptions = evt.target.value;

    if (bw && bw.event === null) {
      return;
    }

    var newOptionsArray = [];
    var noneOfTheAboveOption;
    var otherOption;

    // Find any existing "Other" or "None of the Above options"
    for (var i = 0; i < currentOptions.length; i++) {
      if (currentOptions[i].resultNormalized === 99.0) {
        noneOfTheAboveOption = currentOptions[i];
      }
      if (currentOptions[i].resultNormalized === 98.0) {
        otherOption = currentOptions[i];
      }
    }

    for (var i = 0; i < numberOfOptions; i++) {

      var resultNormalized = currentOptions[i] && currentOptions[i].resultNormalized ? currentOptions[i].resultNormalized : i;
      let optionToAdd = new WyshOption();
      optionToAdd.resultNormalized = resultNormalized;
      optionToAdd.resultLiteral = "";

      if (resultNormalized !== 99.0 && resultNormalized !== 98.0) {
        if (i < currentOptions.length) {
          optionToAdd = currentOptions[i];
        }
        else if (i < bw.event.options.length) {
          optionToAdd = bw.event.options[i];
        }
      }
      else {
        optionToAdd.resultLiteral = "";
        optionToAdd.resultNormalized = i;
      }

      newOptionsArray.push(optionToAdd);
    }

    if (otherOption) {
      newOptionsArray.push(otherOption);
    }

    if (noneOfTheAboveOption) {
      newOptionsArray.push(noneOfTheAboveOption);
    }

    newOptionsArray.sort(WyshOption.compareByResultNormalizedAsc);

    console.log(newOptionsArray);
    if (bw) {
      bw.setWyshOptions(newOptionsArray);
      updatedBlockAttributesDict["wyshOptions"] = newOptionsArray;
      setUpdatedBlockAttributesDict(updatedBlockAttributesDict);
    }

    setLocalBlockWysh(bw);
    props.updateBlockCallback(bw, updatedBlockAttributesDict);
  }

  const handleRandomizeWyshOptions = (evt) => {
    var bw = localBlockWysh;
    bw.randomizeWyshOptions = !bw.randomizeWyshOptions;
    updatedBlockAttributesDict["randomizeWyshOptions"] = bw.randomizeWyshOptions;
    updatedBlockAttributesDict["mcIncludeNoneOfTheAbove"] = bw.mcIncludeNoneOfTheAbove;
    updatedBlockAttributesDict["mcIncludeOther"] = bw.mcIncludeOther;
    updatedBlockAttributesDict["mcOptionsLimit"] = bw.mcOptionsLimit;
    updatedBlockAttributesDict["mustViewAll"] = bw.mustViewAll;

    setUpdatedBlockAttributesDict(updatedBlockAttributesDict);
    setLocalBlockWysh(bw);
    props.updateBlockCallback(bw, updatedBlockAttributesDict);
  }

  const handleMcIncludeNoneOfTheAbove = (evt) => {
    var bw = localBlockWysh;
    bw.mcIncludeNoneOfTheAbove = !bw.mcIncludeNoneOfTheAbove;
    updatedBlockAttributesDict["randomizeWyshOptions"] = bw.randomizeWyshOptions;
    updatedBlockAttributesDict["mcIncludeNoneOfTheAbove"] = bw.mcIncludeNoneOfTheAbove;
    updatedBlockAttributesDict["mcIncludeOther"] = bw.mcIncludeOther;
    updatedBlockAttributesDict["mcOptionsLimit"] = bw.mcOptionsLimit;
    updatedBlockAttributesDict["mustViewAll"] = bw.mustViewAll;
    setUpdatedBlockAttributesDict(updatedBlockAttributesDict);
    setupSpecialOptions(bw);
    props.updateBlockCallback(bw, updatedBlockAttributesDict);
  }

  const handleMcIncludeOther = (evt) => {
    var bw = localBlockWysh;
    bw.mcIncludeOther = !bw.mcIncludeOther;
    updatedBlockAttributesDict["randomizeWyshOptions"] = bw.randomizeWyshOptions;
    updatedBlockAttributesDict["mcIncludeNoneOfTheAbove"] = bw.mcIncludeNoneOfTheAbove;
    updatedBlockAttributesDict["mcIncludeOther"] = bw.mcIncludeOther;
    updatedBlockAttributesDict["mcOptionsLimit"] = bw.mcOptionsLimit;
    updatedBlockAttributesDict["mustViewAll"] = bw.mustViewAll;
    setUpdatedBlockAttributesDict(updatedBlockAttributesDict);
    setupSpecialOptions(bw);
    props.updateBlockCallback(bw, updatedBlockAttributesDict);
  }

  const handleMustViewAll = (evt) => {
    var bw = localBlockWysh;
    bw.mustViewAll = !bw.mustViewAll;
    updatedBlockAttributesDict["randomizeWyshOptions"] = bw.randomizeWyshOptions;
    updatedBlockAttributesDict["mcIncludeNoneOfTheAbove"] = bw.mcIncludeNoneOfTheAbove;
    updatedBlockAttributesDict["mcIncludeOther"] = bw.mcIncludeOther;
    updatedBlockAttributesDict["mcOptionsLimit"] = bw.mcOptionsLimit;
    updatedBlockAttributesDict["mustViewAll"] = bw.mustViewAll;
    setUpdatedBlockAttributesDict(updatedBlockAttributesDict);
    setLocalBlockWysh(bw);
    props.updateBlockCallback(bw, updatedBlockAttributesDict);
  }

  const handleMcOptionsLimitChange = (evt) => {
    var bw = localBlockWysh;
    bw.mcOptionsLimit = parseInt(evt.target.value);
    updatedBlockAttributesDict["randomizeWyshOptions"] = bw.randomizeWyshOptions;
    updatedBlockAttributesDict["mcIncludeNoneOfTheAbove"] = bw.mcIncludeNoneOfTheAbove;
    updatedBlockAttributesDict["mcIncludeOther"] = bw.mcIncludeOther;
    updatedBlockAttributesDict["mcOptionsLimit"] = bw.mcOptionsLimit;
    updatedBlockAttributesDict["mustViewAll"] = bw.mustViewAll;
    setUpdatedBlockAttributesDict(updatedBlockAttributesDict);
    setLocalBlockWysh(bw);
    props.updateBlockCallback(bw, updatedBlockAttributesDict);
  }

  const handleBinaryIconSetIdChange = (evt) => {

    let binaryIconSetID = evt.target.value;

    var bw = localBlockWysh;

    if (bw) {
      bw.binaryIconSetID = binaryIconSetID;
      updatedBlockAttributesDict["binaryIconSetID"] = bw.binaryIconSetID;
      setUpdatedBlockAttributesDict(updatedBlockAttributesDict);
    }

    setUpdatedBlockAttributesDict(updatedBlockAttributesDict);
    setLocalBlockWysh(bw);
    props.updateBlockCallback(bw, updatedBlockAttributesDict);
  }

  const handleDefaultFreeResponseQuestionChange = (evt) => {

    var bw = localBlockWysh;
    
    if (bw) {
      bw.freeResponseQuestions = [evt.target.value];
      updatedBlockAttributesDict["freeResponseQuestions"] = bw.freeResponseQuestions;
      setUpdatedBlockAttributesDict(updatedBlockAttributesDict);
      setLocalBlockWysh(bw);
      props.updateBlockCallback(bw, updatedBlockAttributesDict);
    }
  }

  const handleOptionNameChange = (idx) => (evt) => {
    editOption(idx, evt.target.value);
  }

  const handleRandomizeStimuli = (randomize) => {
    
    var bw = localBlockWysh;
    bw.randomize = randomize;

    if (bw.randomize === false ) {
      // clear out settings that cannot be enabled when a Swydget is randomized
      bw.issueSubGroups = false;
      bw.subGroupCount = 1;
      bw.limitFreeResponse = false;
      bw.limitFreeResponseCount = 1;
    }
    
    setLocalBlockWysh(bw);
    props.updateBlockCallback(bw, updatedBlockAttributesDict);
  }

  const handleIssueSubGroupClicked = (issueSubGroups) => {

    var bw = localBlockWysh;

    if (bw) {
      bw.issueSubGroups = issueSubGroups;

      if (bw.issueSubGroups === false) {
        bw.subGroupCount = 1;
      }
      setLocalBlockWysh(bw);
      props.updateBlockCallback(bw, updatedBlockAttributesDict);
    }
  }

  const handleSubGroupCountChanged = (evt) => {

    var bw = localBlockWysh;
    bw.subGroupCount = parseInt(evt.target.value);
    setLocalBlockWysh(bw);
    props.updateBlockCallback(bw, updatedBlockAttributesDict);
  }

  const handleLimitFreeResponseClicked = (limitFreeResponse) => {

    var bw = localBlockWysh;

    if (bw) {
      bw.limitFreeResponse = limitFreeResponse;

      if (bw.limitFreeResponse === false) {
        bw.limitFreeResponseCount = 1;
      }
      setLocalBlockWysh(bw);
      props.updateBlockCallback(bw, updatedBlockAttributesDict);
    }
  }

  const handleLimitFreeResponseCountChanged = (evt) => {

    var bw = localBlockWysh;
    bw.limitFreeResponseCount = parseInt(evt.target.value);
    setLocalBlockWysh(bw);
    props.updateBlockCallback(bw, updatedBlockAttributesDict);
  }

  const editOption = (index, value) => {

    var bw = localBlockWysh;
    let currentOptions = bw.wyshOptions;
    currentOptions[index].resultLiteral = value;

    if (bw) {
      bw.setWyshOptions(currentOptions);
      updatedBlockAttributesDict["wyshOptions"] = currentOptions;
      setUpdatedBlockAttributesDict(updatedBlockAttributesDict);
    }
    
    setLocalBlockWysh(bw);
    props.updateBlockCallback(bw, updatedBlockAttributesDict);
  }

  const removeOption = (index) => {

    var bw = localBlockWysh;
    let currentOptions = bw.wyshOptions;
    currentOptions.splice(index, 1);

    if (bw) {
      bw.setWyshOptions(currentOptions);
      updatedBlockAttributesDict["wyshOptions"] = currentOptions;
      setUpdatedBlockAttributesDict(updatedBlockAttributesDict);
    }

    setLocalBlockWysh(bw);
    props.updateBlockCallback(bw, updatedBlockAttributesDict);
  }

  const setupSpecialOptions = (blockWysh) => {

    var currentWyshOptions = blockWysh.wyshOptions;
    var otherOption = null;
    var noneOfTheAboveOption = null;

    // Get OTHER: 98.0 and remove it from the currentWyshOptions
    for (var i = 0; i < currentWyshOptions.length; i++) {
      if (currentWyshOptions[i].resultNormalized === 98.0) {
        otherOption = currentWyshOptions[i];
        currentWyshOptions.splice(i, 1);
      }
    }

    // Get NONE OF THE ABOVE: 99.0 and remove it from the currentWyshOptions
    for (i = 0; i < currentWyshOptions.length; i++) {
      if (currentWyshOptions[i].resultNormalized === 99.0) {
        noneOfTheAboveOption = currentWyshOptions[i];
        currentWyshOptions.splice(i, 1);
      }
    }

    // Add OTHER: 98.0 back in, if appropriate
    if (blockWysh.mcIncludeOther) {
      if (otherOption) {
        currentWyshOptions.push(otherOption)
      }
      else {
        otherOption = new WyshOption();
        otherOption.resultLiteral = "Other";
        otherOption.resultNormalized = 98.0;
        currentWyshOptions.push(otherOption);
      }
    }

    // Add NONE OF THE ABOVE: 99.0 back in, if appropriate
    if (blockWysh.mcIncludeNoneOfTheAbove) {
      if (noneOfTheAboveOption) {
        currentWyshOptions.push(noneOfTheAboveOption)
      }
      else {
        noneOfTheAboveOption = new WyshOption();
        noneOfTheAboveOption.resultLiteral = "None of the Above";
        noneOfTheAboveOption.resultNormalized = 99.0;
        currentWyshOptions.push(noneOfTheAboveOption);
      }
    }

    if (blockWysh) {
      blockWysh.setWyshOptions(currentWyshOptions);
      updatedBlockAttributesDict["wyshOptions"] = currentWyshOptions;
      setUpdatedBlockAttributesDict(updatedBlockAttributesDict);
    }

    setLocalBlockWysh(blockWysh);
    props.updateBlockCallback(blockWysh, updatedBlockAttributesDict);
  }

  const toggleFreeResponseEnabled = (blockWysh, optionValue) => {
    
    console.log(optionValue);
    

    if (blockWysh.hiddenFreeResponseOptions.includes(optionValue)) {
      blockWysh.hiddenFreeResponseOptions.splice(blockWysh.hiddenFreeResponseOptions.indexOf(optionValue), 1);
    }
    else {
      blockWysh.hiddenFreeResponseOptions.push(optionValue);
    }

    updatedBlockAttributesDict["hiddenFreeResponseOptions"] = blockWysh.hiddenFreeResponseOptions;
    setUpdatedBlockAttributesDict(updatedBlockAttributesDict);
    setLocalBlockWysh(blockWysh);
    props.updateBlockCallback(blockWysh, updatedBlockAttributesDict);
  }

  const generateChoiceFields = () => {

    const bw = localBlockWysh;
    let currentOptions = bw.wyshOptions;

    return (
        currentOptions.map((option, idx) => (
          <div key={idx} className="rowContainerSpaced marginBottom">
            <SwytchbackTextField
              id="info-input"
              style={{width: '75%'}}
              value={option.resultLiteral}
              onChange={handleOptionNameChange(idx)}
              margin="normal"
              variant="outlined"
              placeholder="Name..."
              maxLength={192}
            />
            { option.resultNormalized !== 99.0 && option.resultNormalized !== 98.0 &&
              <SwytchbackFrameButton
                className="swytchbackButton textFieldHeight" 
                style={{width: '24%'}}
                onClick={() => removeOption(idx)}>
                Remove
              </SwytchbackFrameButton>
            }
          </div>
        ))
    )
  }

  const generateMenuItems = () => {

    const items = []

    const binaryOptions = BinaryIconSet.getOrderedList();
    binaryOptions.map(
      (iconSet) => {
       items.push(<MenuItem key={iconSet.binaryIconSetID} value={iconSet.binaryIconSetID}>{iconSet.name}</MenuItem>) 
      }
    )

    return items;
  }

  const generateDefaultFreeResponseCheckboxes = (stimulus) => {

    // Display Logic: show if response =

    // Here, I have a stimulus. That stimulus has a filter. That filter tells me
    // if this stimulus should have it's box checked and which box should be checked



    var checkboxes = [];

    let divStyle = {
      padding: 0,
      margin: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      flexDirection: 'column'
    }

    if (stimulus) {

      if (stimulus.questionType === "binary") {

        checkboxes.push(
          <div key={"verbatim-" + 0} className="rowContainerSpaced marginBottom">
            <SwytchbackTextField
              id={"verbatim-" + 0}
              style={{width: '70%'}}
              value={"No"}
              margin="normal"
              variant="outlined"
            />
            <div style={{width: '25%'}} className="rowContainer">
              <SwytchbackFrameButton
                className={!(stimulus.hiddenFreeResponseOptions.includes(0)) ? "swytchbackButton swytchbackButtonActive left" : "swytchbackButton left"} 
                style={{width: '50%'}}
                disabled={stimulus.hiddenFreeResponseOptions.includes(0) ? false : true} 
                onClick={(e) => toggleFreeResponseEnabled(stimulus, 0, e)}>
                Yes
              </SwytchbackFrameButton>
              <SwytchbackFrameButton
                className={(stimulus.hiddenFreeResponseOptions.includes(0)) ? "swytchbackButton swytchbackButtonActive right" : "swytchbackButton right"} 
                style={{width: '50%'}}
                disabled={stimulus.hiddenFreeResponseOptions.includes(0) ? true : false} 
                onClick={(e) => toggleFreeResponseEnabled(stimulus, 0, e)}>
                No
              </SwytchbackFrameButton>
            </div>
          </div>
        );
        checkboxes.push(
          <div key={"verbatim-" + 1} className="rowContainerSpaced marginBottom">
            <SwytchbackTextField
              id={"verbatim-" + 1}
              style={{width: '70%'}}
              value={"Yes"}
              margin="normal"
              variant="outlined"
            />
            <div style={{width: '25%'}} className="rowContainer">
              <SwytchbackFrameButton
                className={!(stimulus.hiddenFreeResponseOptions.includes(1)) ? "swytchbackButton swytchbackButtonActive left" : "swytchbackButton left"} 
                style={{width: '50%'}}
                disabled={stimulus.hiddenFreeResponseOptions.includes(1) ? false : true} 
                onClick={(e) => toggleFreeResponseEnabled(stimulus, 1, e)}>
                Yes
              </SwytchbackFrameButton>
              <SwytchbackFrameButton
                className={(stimulus.hiddenFreeResponseOptions.includes(1)) ? "swytchbackButton swytchbackButtonActive right" : "swytchbackButton right"} 
                style={{width: '50%'}}
                disabled={stimulus.hiddenFreeResponseOptions.includes(1) ? true : false} 
                onClick={(e) => toggleFreeResponseEnabled(stimulus, 1, e)}>
                No
              </SwytchbackFrameButton>
            </div>
          </div>
        );
      }
      else if (stimulus.questionType === "scalar" || stimulus.questionType === "multiple-choice" || stimulus.questionType === "single-select" || stimulus.questionType === "nps" || stimulus.questionType === "csat") {
        for (var i = 0; i < stimulus.wyshOptions.length; i++) {

          let score = stimulus.wyshOptions[i].resultNormalized;

          checkboxes.push(
            <div key={"verbatim-" + i} className="rowContainerSpaced marginBottom">
              <SwytchbackTextField
                id={"verbatim-" + i}
                style={{width: '70%'}}
                value={(i + 1) + " - " + stimulus.wyshOptions[i].resultLiteral}
                margin="normal"
                variant="outlined"
              />
              <div style={{width: '25%'}} className="rowContainer">
                <SwytchbackFrameButton
                  className={!(stimulus.hiddenFreeResponseOptions.includes(stimulus.wyshOptions[i].resultNormalized)) ? "swytchbackButton swytchbackButtonActive left" : "swytchbackButton left"} 
                  style={{width: '50%'}}
                  disabled={stimulus.hiddenFreeResponseOptions.includes(stimulus.wyshOptions[i].resultNormalized) ? false : true} 
                  onClick={(e) => toggleFreeResponseEnabled(stimulus, score, e)}>
                  Yes
                </SwytchbackFrameButton>
                <SwytchbackFrameButton 
                  className={(stimulus.hiddenFreeResponseOptions.includes(stimulus.wyshOptions[i].resultNormalized)) ? "swytchbackButton swytchbackButtonActive right" : "swytchbackButton right"} 
                  style={{width: '50%', borderLeft: '1px solid white'}}
                  disabled={stimulus.hiddenFreeResponseOptions.includes(stimulus.wyshOptions[i].resultNormalized) ? true : false} 
                  onClick={(e) => toggleFreeResponseEnabled(stimulus, score, e)}>
                  No
                </SwytchbackFrameButton>
              </div>
            </div>
          );
        }
      }
    }

    return <div className="columnContainer">{checkboxes}</div>;
  }

  return (
    <div id="blockSequentialMonadicConfigurationComponent" className="hookFullPaneContainer standardModalHeight fullWidth">
      <div className="hookContentContainer column">
        <PromptWidget
          readOnly={props.readOnly}
          title={"Block Name:"}
          value={localBlockWysh.product.name}
          callback={handleBlockNameChange}
        />
        <SequentialMonadicQuestionTypeSelector
          readOnly={props.readOnly}
          questionType={localBlockWysh.questionType}
          callback={handleQuestionTypeChange}
          questionDescription={localBlockWysh.getQuestionTypeDescription()}
        />
        <PromptWidget
          readOnly={props.readOnly}
          title={"Question Prompt:"}
          value={localBlockWysh.caption}
          callback={handleDefaultPromptChange}
        />
        <BlockOptionsFormWidget
          questionType={localBlockWysh.questionType}
          wyshOptions={localBlockWysh.wyshOptions}
          getOptionsCount={getOptionsCount}
          handleNumberOfOptionsChange={handleNumberOfOptionsChange}
          generateSelectOptions={generateSelectOptions}
          mcOptionsLimit={localBlockWysh.mcOptionsLimit}
          handleMcOptionsLimitChange={handleMcOptionsLimitChange}
          randomizeWyshOptions={localBlockWysh.randomizeWyshOptions}
          handleRandomizeWyshOptions={handleRandomizeWyshOptions}
          mcIncludeNoneOfTheAbove={localBlockWysh.mcIncludeNoneOfTheAbove}
          handleMcIncludeNoneOfTheAbove={handleMcIncludeNoneOfTheAbove}
          mcIncludeOther={localBlockWysh.mcIncludeOther}
          handleMcIncludeOther={handleMcIncludeOther}
          mustViewAll={localBlockWysh.mustViewAll}
          handleMustViewAll={handleMustViewAll}
          binaryIconSetID={localBlockWysh.binaryIconSetID}
          handleBinaryIconSetIdChange={handleBinaryIconSetIdChange}
          generateChoiceFields={generateChoiceFields}
          generateMenuItems={generateMenuItems}
        />
        <RandomizeWidget 
          blockWysh={localBlockWysh} 
          callback={handleRandomizeStimuli}
        />
        <BatchOptionsWidget
          readOnly={props.readOnly}
          block={localBlockWysh}
          handleIssueSubGroupClicked={handleIssueSubGroupClicked}
          handleSubGroupCountChanged={handleSubGroupCountChanged}
          generateSelectOptions={generateSelectOptions}
          handleLimitFreeResponseClicked={handleLimitFreeResponseClicked}
          handleLimitFreeResponseCountChanged={handleLimitFreeResponseCountChanged}
        />
        <VerbatimOptionsWidget
          readOnly={props.readOnly}
          block={localBlockWysh}
          handleDefaultFreeResponseQuestionChange={handleDefaultFreeResponseQuestionChange}
          generateDefaultFreeResponseCheckboxes={generateDefaultFreeResponseCheckboxes}
        />
      </div>
    </div>  
  );
}