import BlockType from "./BlockType";

export default class SequentialMonadicBlockType extends BlockType {

  constructor(blockTypeId, name) {
    super(blockTypeId, name);
    this.isQuantitativeAndQualitativeReadOnly = true;
  }

  getThresholdLabels() {
    return [
      "Top Box",
      "Top Two Box"
    ]
  }

  getChildStimuliType(block) {
    const wyshes = block.orderedWyshList.wyshes;
    return wyshes.length > 0 ? wyshes[0].questionType : "binary"
  }

  getChildStimuliOptionsLength(block) {
    const wyshes = block.orderedWyshList.wyshes;
    return wyshes.length > 0 ? wyshes[0].wyshOptions.length : 0
  }

  calculateTurfThreshold(block, turfThreshhold) {

    const qt = this.getChildStimuliType(block)
    
    
    if (turfThreshhold === 0) {
      if (qt === "binary") {
        return 1;
      }
      else {
        // Top Box
        const optionsLength = this.getChildStimuliOptionsLength(block);
        return optionsLength > 1 ? optionsLength - 1 : 1; // Options must be more than 2
        return this.getChildStimuliOptionsLength(block);
      }
    }
    else {
      if (block.getQuestionType() === "binary") {
        return 1;
      }
      else {
        // Top Two Box
        const optionsLength = this.getChildStimuliOptionsLength(block);
        return optionsLength > 1 ? optionsLength - 2 : 1; // Options must be more than 2
      }
    }
  }

  generateTurfReachResults(block, turfThreshhold) {

    const results = {};

    const takerMidArray = [];

    results["sys_RespNum"] = takerMidArray

    const takerDict = this._generateTakerDict(block);
    const threshold = this.calculateTurfThreshold(block, turfThreshhold);
    
    const stimuliArray = block.orderedWyshList.getOrderedWyshes();
    const stimuliProductIdArray = [];
    
    for (const stimulus of stimuliArray) {
      stimuliProductIdArray.push(stimulus.product.productId);
      results[stimulus.product.productId] = [];
    }

    for (const takerMid in takerDict) {

      let isTakerValid = true;
      const productsEncounteredByTaker = new Set();
      // the taker MUST have decision for EVERY stimuli
    
      for (const dec of takerDict[takerMid].decisions) {
        productsEncounteredByTaker.add(dec.productId);
      }

      stimuliProductIdArray.map((productId) => {
        if (productsEncounteredByTaker.has(productId) === false) {
          isTakerValid = false;
        }
      })
      


      if (isTakerValid) {
        const takerRow = [];
        takerMidArray.push(takerMid);

        for (const productId of stimuliProductIdArray) {
          
          for (const dec of takerDict[takerMid].decisions) {
            if (dec.productId === productId) {
              results[productId].push(dec.resultNormalized >= threshold ? 1: 0);
              break;
            }
          }
        }
      }
      else {
        console.log("Invalid taker: " + takerMid);
      }
            
    }

    return results;
  }

  determineCombinationLimitMax(includedStimuliProductIds) {
    return includedStimuliProductIds.length;
  }

  appendBlock(block, stimuliArray) {

    const myArray = [
      "Q" + block.questionNumber,
      "",
      block.getWyshPrompt(),
      block.getQuestionType(),
      "",
      ""
    ];

    stimuliArray.push(myArray);
    stimuliArray.push([]); // Insert extra row after appending myself.

    const fow = block.getFilteredOrderedWyshes();

    if (block.questionType === "binary") {
      // Top Box 1
      for (const stimulus of fow) {
        this._appendBinaryTopBox(stimulus, stimuliArray);
      }
      stimuliArray.push([]); // Insert extra row after appending TopBox.
    }
    else {
      // Top Box 1
      for (const stimulus of fow) {
        this._appendTopBox(stimulus, stimuliArray, 1);
      }
      stimuliArray.push([]); // Insert extra row after appending TopBox.
      
      // Top Box 2 
      for (const stimulus of fow) {
        this._appendTopBox(stimulus, stimuliArray, 2);
      }
      stimuliArray.push([]); // Insert extra row after appending TopBox.
      
      // Top Box 3
      for (const stimulus of fow) {
        this._appendTopBox(stimulus, stimuliArray, 3);
      }
      stimuliArray.push([]); // Insert extra row after appending TopBox.
    }
    
    

    for (const stimulus of fow) {
      stimulus.appendMyself(stimuliArray);
    }
  }

  _appendTopBox(stimulus, stimuliArray, count = 1) {
    const myArray = [
      "Q" + stimulus.questionNumber,
      stimulus.product.description,
      stimulus.getWyshPrompt(),
      stimulus.getQuestionType(),
    ];

    const snapshotKeys = Array.from(stimulus.event.snapshotContainer.snapshots.keys());
  
    let topBoxOptions = ""
    for (let i = count; i > 0; i--) {
      const optionIndex = 1 + stimulus.wyshOptions.length - i;
      topBoxOptions += optionIndex;
      if (i > 1){
        topBoxOptions += ", ";
      }
    }

    myArray.push(topBoxOptions);
    myArray.push("T" + count+ "B");

    for (const snapshotKey of snapshotKeys) {
      const snapshot = stimulus.event.snapshotContainer.snapshots.get(snapshotKey);
      if (snapshot) {
        stimulus.applySnapshot(snapshot);
      }

      let totalDecisionCount = 0;
      
      if (stimulus.wyshOptions.length > 0) {
        for (let i = count; i > 0; i--) {
          totalDecisionCount += stimulus.wyshOptions[stimulus.wyshOptions.length - i].decisionsCount;
        }
      }
      
      myArray.push(totalDecisionCount);
    }

    for (const snapshotKey of snapshotKeys) {
      const snapshot = stimulus.event.snapshotContainer.snapshots.get(snapshotKey);
      if (snapshot) {
        stimulus.applySnapshot(snapshot);
      }

      let totalDecisionCount = 0;

      if (stimulus.wyshOptions.length > 0) {
        for (let i = count; i > 0; i--) {
          totalDecisionCount += stimulus.wyshOptions[stimulus.wyshOptions.length - i].decisionsCount;
        }
      }

      myArray.push(totalDecisionCount / stimulus.takerCount);

    }

    stimuliArray.push(myArray);
  }

  _appendBinaryTopBox(stimulus, stimuliArray) {
    const myArray = [
      "Q" + stimulus.questionNumber,
      stimulus.product.description,
      stimulus.getWyshPrompt(),
      stimulus.getQuestionType(),
    ];

    const snapshotKeys = Array.from(stimulus.event.snapshotContainer.snapshots.keys());
  
    myArray.push("1");
    myArray.push("T1B");

    for (const snapshotKey of snapshotKeys) {
      const snapshot = stimulus.event.snapshotContainer.snapshots.get(snapshotKey);
      if (snapshot) {
        stimulus.applySnapshot(snapshot);
      }

      myArray.push(stimulus.aggregatedResults.likesCount);
    }

    for (const snapshotKey of snapshotKeys) {
      const snapshot = stimulus.event.snapshotContainer.snapshots.get(snapshotKey);
      if (snapshot) {
        stimulus.applySnapshot(snapshot);
      }

      myArray.push(stimulus.aggregatedResults.likesCount / stimulus.takerCount);

    }

    stimuliArray.push(myArray);
  }
}