import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "../../App.css";
import "../../css/nav.css";
import loginStore from '../../stores/LoginStore';
import { Link, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Box, IconButton, Container, Avatar, Button, Tooltip, Menu, MenuItem, Typography  } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';


export default function Header(props) {
  
  const navigate = useNavigate();
  const loggedInUser = loginStore.getLoggedInUser();
  const messageCount = loggedInUser ? loggedInUser.myUnacknowldgedNotifications.length : 0;
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const rootClass = location.pathname === "/" ? "active" : "";
  const createSurveyClass = location.pathname.indexOf("create_survey") > -1 ? "active" : "";
  const notificationsClass = location.pathname.match(/^\/notifications/) ? "active" : "";
  const loginClass = location.pathname.match(/^\/account_info/) ? "active" : "";
  const navClass = collapsed ? "collapse" : "";

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const pages = [
    {
      title: 'Create New',
      destination: "/create_survey/select_type"
    },
    {
      title: 'Swydgets',
      destination: "/"
    },
    {
      title: 'Notifications',
      destination: "/notifications"
    }
  ]

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  
  const handleCloseNavMenu = (page) => {
    setAnchorElNav(null);
    navigate(page.destination);
  };

  const navigateToAccountInfo = () => {
    setAnchorElUser(null);
    navigate("/account_info");
  };
  
  if (location.pathname.startsWith("/swydget") === true) {
    return <header></header>;
  }
  else {
    return (
      <header className="headerStyles">
        <AppBar position="static">
          <Container>
            <Toolbar disableGutters>
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                  mr: 2,
                  display: { xs: 'none', md: 'flex', marginRight: 50 },
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                <span className="swytchStyle">Swytch</span><span className="backStyle">back</span>
              </Typography>

              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  {pages.map((page) => (
                    <MenuItem key={page.destination} onClick={() => handleCloseNavMenu(page)}>
                      <Typography textAlign="center">{page.title}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              <Typography
                variant="h5"
                noWrap
                component="a"
                href="/"
                sx={{
                  mr: 2,
                  display: { xs: 'flex', md: 'none' },
                  flexGrow: 1,
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                <span className="swytchStyle">Swytch</span><span className="backStyle">back</span>
              </Typography>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                {pages.map((page) => (
                  <Button
                    key={page.destination}
                    onClick={() => handleCloseNavMenu(page)}
                    sx={{ 
                      '&:hover': {
                        bgcolor: 'rgba(0, 0, 0, 0.08)', // Change the background color on hover
                      },
                      my: 2, 
                      color: 'white', 
                      display: 'block', 
                      textTransform: 'capitalize', 
                      fontSize: 16
                    }}
                  >
                    {page.title}
                  </Button>
                ))}
              </Box>

              <Box sx={{ flexGrow: 0 }}>
                {loggedInUser !== null && 
                <Tooltip title="Open settings">
                  <IconButton onClick={navigateToAccountInfo} sx={{ p: 0 }}>
                    <Avatar alt={loggedInUser ? loggedInUser.firstName : "no user" + " " + loggedInUser ? loggedInUser.lastName : "no user last name"} src={loggedInUser ? loggedInUser.getProfilePhotoUrl() : "No user"} />
                  </IconButton>
                </Tooltip>
                }
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </header>
    );  
  }
};