import React from "react";
import '../../../css/swytchbackModal.css';
import '../../../css/swytchbackStyles.css';
import SwytchbackTextField from "../controls/SwytchbackTextField";

const VerbatimOptionsWidget = ({
  readOnly,
  block, 
  handleDefaultFreeResponseQuestionChange, 
  generateDefaultFreeResponseCheckboxes
}) => {

  const text = block.freeResponseQuestions.length > 0 ? block.freeResponseQuestions[0] : ''

  return (
    <div className="widget fullWidth">
      <div className='title marginBottom'>
        Verbatim:
      </div>
      <div className='content rowContainerSpaced'>
      { readOnly === false ?
        <SwytchbackTextField
          id="free-response-input"
          style={{marginBottom: 10}}
          value={text}
          onChange={handleDefaultFreeResponseQuestionChange}
          margin="normal"
          variant="outlined"
        />
      :
        <div className="swytchbackAdminControl">
          <div>Default Verbatim:</div>
          <div>{text}</div>
        </div>
      }
        <div className="swytchbackAdminControl">
          {generateDefaultFreeResponseCheckboxes(block)}
        </div>  
      </div>
    </div>  
  );
}

export default VerbatimOptionsWidget;