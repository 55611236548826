import React, { useState, useEffect } from "react";
import { Modal, Tab, Tabs } from 'react-bootstrap';
import classes from "../../../css/modules/TurfModal.module.css";
import "../../../css/swytchbackModal.css"
import SwytchbackButton from '../../admin/controls/SwytchbackButton';
import TurfResultsWidget from './TurfResultsWidget';
import SwytchbackSelect from '../../admin/controls/SwytchbackSelect';
import { Checkbox, CircularProgress, FormControlLabel, FormGroup, MenuItem } from '@mui/material';
import SwytchbackFrameButton from "../../admin/controls/SwytchbackFrameButton";
import AdminActionsHook from "../../actions/AdminConsoleActionsHook";
import useHttpPost from "../../utilities/AxiosHttpPost";
import TurfResults from "../../../classes/turf/TurfResults";
import MeService from "../../../services/MeService";
import BlockTypes from "../../../classes/BlockTypes";
import TurfStimuliSelectorModal from "./TurfStimuliSelectorModal";


const TurfModal = ({user, block, snapshot, showTurfModal, closeTurfModal}) => {

  const { postData, postLoading, postError, post } = useHttpPost(user);
  const [includedStimuliProductIds, setIncludedStimuliProductIds] = useState([]);
  const [requiredStimuliProductIds, setRequiredStimuliProductIds] = useState([]);
  const [combinationsLimit, setCombinationsLimit] = useState(0);
  const [turfThreshhold, setTurfThreshold] = useState(block.getBlockType().getThresholdLabels()[0].value);
  const [isWorking, setIsWorking] = useState(false);

  const limit = 40;
      
  let start = 0;
  let end = 0;

  let turfReachResults = {};
  
  useEffect(() => {

    if(includedStimuliProductIds.length === 0) {
      if (block.getBlockType().equals(BlockTypes.MAXDIFF) === true || 
          block.getBlockType().equals(BlockTypes.PAIRWISE) === true || 
          block.getBlockType().equals(BlockTypes.SEQUENTIALMONADIC) === true) {
        const stimuli = block.orderedWyshList.getFilteredSortedWyshes("desc", null);
        
        const productIds = []
        stimuli.map((stimulus) => {
          if (productIds.length < limit){
            productIds.push(stimulus.product.productId);
          }
        })

        setIncludedStimuliProductIds(productIds);
        setCombinationsLimit(block.getBlockType().determineCombinationLimitMax(productIds));
      }
    }
    
  }, [snapshot])

  const generateTurfResults = () => {

    block.turfResults.sort((a, b) => {
      return b.created - a.created;
    })

    if (block.turfResults && block.turfResults.length > 0) {
      return (
        <div className="columnCenteredContainer">
          {block.turfResults.map((turfResults) => {
            return (
              <TurfResultsWidget key={turfResults.created} turfResults={turfResults} swydget={block.event} snapshot={snapshot} />
            )
          })}
        </div>
      )
    }
    else {
      return (
        <div>
          no turf results
        </div>
      )
    }
  }

  const handleIncludedStimuliChange = (evt) => {

    const { name } = evt.target;

    const idx = includedStimuliProductIds.indexOf(name);

    if (idx !== -1) {
      includedStimuliProductIds.splice(idx, 1);
      setIncludedStimuliProductIds([...includedStimuliProductIds]);
      setCombinationsLimit(block.getBlockType().determineCombinationLimitMax(includedStimuliProductIds));

      const requiredIdx = requiredStimuliProductIds.indexOf(name);
      if (requiredIdx !== -1) {
        requiredStimuliProductIds.splice(requiredIdx, 1);
        setRequiredStimuliProductIds([...requiredStimuliProductIds])
      }
    }
    else {
      if (includedStimuliProductIds.length < limit) {

        const includedProductIds = [
          ...includedStimuliProductIds,
          name
        ]

        setIncludedStimuliProductIds(includedProductIds)
        setCombinationsLimit(block.getBlockType().determineCombinationLimitMax(includedProductIds));
      }
    }
  }

  const resetIncludedStimuliProductIds = (count) => {
    if (block.getBlockType().equals(BlockTypes.MAXDIFF) === true || 
        block.getBlockType().equals(BlockTypes.PAIRWISE) === true || 
        block.getBlockType().equals(BlockTypes.SEQUENTIALMONADIC) === true) {
      const stimuli = block.orderedWyshList.getFilteredSortedWyshes("desc", null);
      
      const productIds = []
      stimuli.map((stimulus) => {
        if (productIds.length < count){
          productIds.push(stimulus.product.productId);
        }
      })

      setIncludedStimuliProductIds(productIds);
      setCombinationsLimit(block.getBlockType().determineCombinationLimitMax(productIds));
    }
  }


  const handleRequiredStimuliChange = (evt) => {

    const { name } = evt.target;

    const idx = requiredStimuliProductIds.indexOf(name);    

    if (idx !== -1) {
      requiredStimuliProductIds.splice(idx, 1);
      setRequiredStimuliProductIds([...requiredStimuliProductIds])
    }
    else {
      setRequiredStimuliProductIds([
        ...requiredStimuliProductIds,
        name
      ])
    }
  }

  const generateIncludedStimuliControl = () => {
    
    return (
      <FormGroup style={{
        width: '100%',
        margin: 0,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexWrap: 'wrap'
      }}>
        {block.orderedWyshList.getFilteredSortedWyshes("desc", null).map((stimulus) => {

          const isIncluded = includedStimuliProductIds.includes(stimulus.product.productId); 
          
          return (
            <div key={stimulus.product.productId} className={`${classes.rowContainer}`}>
              <FormControlLabel
                style={{ margin: 0, marginRight: 30,padding: 0 }}
                control={
                  <Checkbox
                    checked={includedStimuliProductIds.includes(stimulus.product.productId)}
                    onChange={handleIncludedStimuliChange}
                    name={stimulus.product.productId}
                    style={{ color: '#ffffff' }}
                  />
                }
                label={
                  <div className={`${classes.stimulusLabel}`}>
                    <div>
                      {stimulus.product.getUnformattedText()} 
                    </div>
                    <div>
                      {stimulus.getAverageScore().toFixed(1)}
                    </div>  
                  </div>
                }
              />
              {isIncluded &&
              <FormControlLabel
                style={{ margin: 0, padding: 0 }}
                control={
                  <Checkbox
                    checked={requiredStimuliProductIds.includes(stimulus.product.productId)}
                    onChange={handleRequiredStimuliChange}
                    name={stimulus.product.productId}
                    style={{ color: '#ffffff' }}
                  />
                }
                label={
                  <div className={`${classes.stimulusLabel}`}>
                    <div>
                      Required
                    </div>
                  </div>
                }
              /> 
              }
            </div>
          )
        }
        )
        }
      </FormGroup>
    )
  }

  const generateReach = () => {
    const { getDecisions } = AdminActionsHook();
    setIsWorking(true);
    getDecisions(user, block.event, getDecisionsCallback);
  }

  const getDecisionsCallback = (decisionsReceivedArray) => {
    block.event.setAllDecisionsArray(decisionsReceivedArray);
    turfReachResults = block.getBlockType().generateTurfReachResults(block, turfThreshhold);
    generateTurf();
  }

  const filterStimuli = () => {
    
    const includedStimuliProductIdsSet = new Set(includedStimuliProductIds);
    includedStimuliProductIdsSet.add("sys_RespNum");

    for (const productId in turfReachResults) {
      if (!includedStimuliProductIdsSet.has(productId)) {
        delete turfReachResults[productId];
      }
    }
  }

  const generateTurf = () => {
    
    start = Date.now()

    // const endpoint = "https://l6zoodfwcejmjjuu4ohog4tmma0flnjd.lambda-url.us-west-2.on.aws/"
    const endpoint = "https://fs7d2i5kvw36m7ttaidqhitfca0pfyje.lambda-url.us-west-2.on.aws/"

    
    filterStimuli();

    const parametersJson = {
      "timeout": 900000,
      "data": turfReachResults,
      "required_stimuli": requiredStimuliProductIds,
      "combinations_limit": combinationsLimit
    }
    
    post(endpoint, parametersJson, turfReachResultsUploadCallback);
    // post(endpoint, parametersJson, fartCallback);
  }

  const fartCallback = (data) => {
    console.log(data);
  }

  const turfReachResultsUploadCallback = (data) => {

    end = Date.now();
    const duration = (end - start) / 1000;
    
    if (snapshot) {
      block.applySnapshot(snapshot);
    }

    const requiredStimuliProductIds = data.results.required_stimuli ? data.results.required_stimuli : [];

    const turfResults = new TurfResults(
      data.results.created, 
      block.getBlockTakerCount(), 
      data.results.message, 
      data.results.success, 
      includedStimuliProductIds,
      requiredStimuliProductIds, 
      turfThreshhold, 
      combinationsLimit,
      data.results.max_diff_results
    );

    const parametersJson = {
      "turf_results": turfResults.toJson(),
      "snapshot_id": snapshot.created.toString(),
      "required_stimuli": requiredStimuliProductIds,
      "included_stimuli": includedStimuliProductIds
    }

    const endpoint = MeService.serverUrl + ":" + MeService.wm_new_port + "/1/events/event/" + block.event.eventId + "/turf/" + block.wyshId;
    const encodedEndpoint = endpoint.replace(/#/g, "%23");

    post(encodedEndpoint, parametersJson, turfResultsSubmittedCallback);
  }

  const turfResultsSubmittedCallback = (data) => {
    setIsWorking(false);
    MeService.getEvent(user.userId, user.password, block.event.eventId);
    closeTurfModal();
  }

  
  return (
    <Modal
      onHide={closeTurfModal}
      size="xl"
      show={showTurfModal}
      enforceFocus={false}
      backdrop="static">
      <Modal.Header className="swytchbackModalHeader" closeButton>
        <Modal.Title className="swytchbackModalTitle">Analysis - Create TURF</Modal.Title>
      </Modal.Header>
      <Modal.Body className="swytchbackModalBody swytchbackGradientPane">
        <div className="fullPaneContainer">
          <div className="contentContainer">
            <div className='fullWidthPane tallModalHeight'>
              <TurfStimuliSelectorModal
                block={block}
                turfThreshhold={turfThreshhold} 
                setTurfThreshold={setTurfThreshold}
                combinationsLimit={combinationsLimit}
                setCombinationsLimit={setCombinationsLimit}
                includedStimuliProductIds={includedStimuliProductIds}
                requiredStimuliProductIds={requiredStimuliProductIds}
                handleIncludedStimuliChange={handleIncludedStimuliChange}
                handleRequiredStimuliChange={handleRequiredStimuliChange}
                resetIncludedStimuliProductIds={resetIncludedStimuliProductIds}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="swytchbackModalFooter">
        {isWorking && (
            <CircularProgress
              size={24}
              thickness={6}
              sx={{
                color: 'green'
              }}
            />
          )
        }
        <SwytchbackButton 
          disabled={isWorking}
          className="swytchbackActiveButton" 
          style={{width: 130, marginLeft: 5, marginRight: 5}} 
          onClick={() => generateReach()}
        >
          Generate TURF
        </SwytchbackButton>
        <SwytchbackButton 
          className="swytchbackActiveButton" 
          style={{width: 130, marginLeft: 5, marginRight: 5}} 
          onClick={closeTurfModal}
        >
          Close
        </SwytchbackButton>
      </Modal.Footer>
    </Modal>
    );
};
export default TurfModal;