import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Delete, Settings } from '@mui/icons-material';
import { Container, Row, Col, Image, Button, Modal, OverlayTrigger, Popover }  from 'react-bootstrap';
import "../css/swytchbackStyles.css";
import UserInfoFormModal from "../components/admin/UserInfoFormModal";
import CredentialsModal from "../components/admin/CredentialsModal";
import FriendsTable from '../components/admin/FriendsTable';
import FriendsTableRow from '../components/admin/FriendsTableRow';
import SegmentsAdmin from '../hooks/admin/user/SegmentsAdmin';
import MessagingCampaignAdmin from '../components/admin/MessagingCampaignAdmin';
import AuthService from '../services/AuthService';
import User from "../classes/User";
import LoginStore from "../stores/LoginStore";
import addContentImg from '../Plus-Trans-96.png';


class AdvancedUserTools extends React.Component {

  constructor() {
    super();
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handleUPasswordChange = this.handleUPasswordChange.bind(this);
    this.setLoggedInUser = this.setLoggedInUser.bind(this);
    this.openSettingsModal = this.openSettingsModal.bind(this);
    this.closeSettingsModal = this.closeSettingsModal.bind(this);
    this.openUserInfoModal = this.openUserInfoModal.bind(this);
    this.closeUserInfoModal = this.closeUserInfoModal.bind(this);
    this.uploadProfilePhoto = this.uploadProfilePhoto.bind(this);
    this.clearImage = this.clearImage.bind(this);
    this.inviteFriend = this.inviteFriend.bind(this);
    this.deleteFriend = this.deleteFriend.bind(this);
    this.logout = this.logout.bind(this);
    this.advancedMode = false;


    // 1. fetch username and password from local storage
    this.loggedInUser = User.createUserFromJsonString(localStorage.getItem("loggedInUserJsonString"));
    this.isUserLoggedIn = false;
    if (this.loggedInUser.userId !== "")
    {
      this.isUserLoggedIn = true
    }

    this.state = {
      showSettingsModal: false,
      showUserInfoModal: false,
      loggedInUser: this.loggedInUser,
      isUserLoggedIn: this.isUserLoggedIn,
      imageFiles: []
    };
  }

  componentWillUnmount() {
    LoginStore.removeListener("auth_add_segment_successful", this.setLoggedInUser);
    LoginStore.removeListener("auth_update_segment_successful", this.setLoggedInUser);
    LoginStore.removeListener("auth_delete_segment_successful", this.setLoggedInUser);
    LoginStore.removeListener("auth_add_messaing_campaign_successful", this.setLoggedInUser);
    LoginStore.removeListener("auth_update_messaing_campaign_successful", this.setLoggedInUser);
    LoginStore.removeListener("auth_delete_messaing_campaign_successful", this.setLoggedInUser);
    LoginStore.removeListener("loginStore_user_logged_in", this.setLoggedInUser);
    LoginStore.removeListener("loginStore_user_update_successful", this.setLoggedInUser);
    LoginStore.removeListener("change-logout", this.setLoggedInUser);
  }

  componentDidMount() {

    LoginStore.on("auth_add_segment_successful", this.setLoggedInUser);
    LoginStore.on("auth_update_segment_successful", this.setLoggedInUser);
    LoginStore.on("auth_delete_segment_successful", this.setLoggedInUser);
    LoginStore.on("auth_add_messaing_campaign_successful", this.setLoggedInUser);
    LoginStore.on("auth_update_messaing_campaign_successful", this.setLoggedInUser);
    LoginStore.on("auth_delete_messaing_campaign_successful", this.setLoggedInUser);
    LoginStore.on("loginStore_user_logged_in", this.setLoggedInUser);
    LoginStore.on("loginStore_user_update_successful", this.setLoggedInUser);
    LoginStore.on("change-logout", this.setLoggedInUser);

    var param = this.getUrlParameter("idempotent");

    if (param) {
      if (param === "true") {
        this.advancedMode = true;
      }
    }

    AuthService.login(this.state.loggedInUser.userId, this.state.loggedInUser.password, "/advanced_user_tools" + this.props.location.search, this.props.navigationHandler);


    let script = document.createElement('script');
    script.setAttribute('id', 'civicScienceScript');
    script.src = "https://get.civicscience.com/jspoll/5/csw-polyfills.js";
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(this.props.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  };

  openSettingsModal() {
    this.setState({ showSettingsModal: true });
  }

  closeSettingsModal() {
    this.setState({ showSettingsModal: false });
  }

  openUserInfoModal() {
    this.setState({ showUserInfoModal: true });
  }

  closeUserInfoModal() {
    this.setState({ showUserInfoModal: false });
  }

  clearImage() {
    this.setState({
      imageFiles: []
    });
  }

  logout() {
    AuthService.logout(this.state.loggedInUser.userId, this.state.loggedInUser.password)
  }


  uploadProfilePhoto() {
    AuthService.uploadProfilePhoto(this.state.loggedInUser, this.state.imageFiles[0]);
  }

  setLoggedInUser() {
    this.loggedInUser = LoginStore.getLoggedInUser();
    if (this.loggedInUser != null) {
      this.isUserLoggedIn = true;
      this.setState({
        loggedInUser: LoginStore.getLoggedInUser(),
        isUserLoggedIn: true,
        showUserInfoModal: false,
        imageFiles: []
      });
    }
    else {
      this.isUserLoggedIn = false;
      this.setState({
        loggedInUser: LoginStore.getLoggedInUser(),
        isUserLoggedIn: false,
        imageFiles: []
      });
    }
  }

  inviteFriend(email, firstName, lastName) {
    AuthService.inviteFriend(this.state.loggedInUser, email, firstName, lastName);
  }

  deleteFriend(friendToDelete) {
    AuthService.deleteFriend(this.state.loggedInUser, friendToDelete);
  }

  handleUsernameChange(event) {
    this.setState({username: event.target.value})
  }

  handleUPasswordChange(event) {
    this.setState({password: event.target.value})
  }

  generateFriendRows() {

    var rows = [];

    if (this.state.loggedInUser.myFriends) {
      for (var i = 0; i < this.state.loggedInUser.myFriends.length; i++) {
        rows.push(<FriendsTableRow
          key={i}
          user={this.state.loggedInUser.myFriends[i]}
          isConnected={this.state.loggedInUser.myFriends[i].isConnected}
          actionHandler={this.deleteFriend}
          icon={<Delete />}
          glyphiconStyle="default"
          confirmationTitle="Remove"
          confimationMessage={"Do you want to remove " +this.state.loggedInUser.myFriends[i].firstName +  " " + this.state.loggedInUser.myFriends[i].lastName + "?"}
          confirmationButtonText="Remove"
        />);
      }
    }

    return rows;
  }

  handleMessageChangeMethod = (evt, mc) => {
    var u = this.state.loggedInUser;

    u.messagingCampaignBucket.messaging_campaigns[mc.messaging_campaign_id].messages[0] = evt.target.value;

    this.setState({
      loggedInUser: u
    });
  }

  handlePhoneNumbersChangeMethod = (evt, mc) => {
    var u = this.state.loggedInUser;

    u.messagingCampaignBucket.messaging_campaigns[mc.messaging_campaign_id].setPhoneNumbers(evt.target.value);

    this.setState({
      loggedInUser: u
    });
  }

  handleUpdateMessagingCampaignName = (evt, user, messagingCampaign) => {
    var u = this.state.loggedInUser;

    u.messagingCampaignBucket.messaging_campaigns[messagingCampaign.messaging_campaign_id].messaging_campaign_name = evt.target.value;

    console.log(u.messagingCampaignBucket.messaging_campaigns[messagingCampaign.messaging_campaign_id].messaging_campaign_name);

    this.setState({
      loggedInUser: u
    });
  }

  getCardHeight = () => {
    var h = window.innerHeight;
    var w = window.innerWidth;
    return h >= w ? 20 : 30;
  }

  render() {
    var rows = this.generateFriendRows();

    var cardHeight = this.getCardHeight();
    var cardWidth = cardHeight;
    var cardHeightStyle = cardHeight + 'vh';
    var cardWidthStyle = cardWidth + 'vh';

    const authPopup = (
      <Popover id="popover-trigger-click" title={this.state.loggedInUser.firstName + " " + this.state.loggedInUser.lastName}>
        <Button className="swytchbackActiveButton" style={{width: '100%', marginBottom: 6}} onClick={this.logout}>
          Sign Out
        </Button>
        <Button className="swytchbackActiveButton" style={{width: '100%'}}   onClick={this.openSettingsModal}>
          Reset Password
        </Button>
      </Popover>
    );

    let headerRowStyle = {
      borderBottom: 2,
      borderBottomColor: '#1994fc',
      borderBottomStyle: 'solid'
    };

    let titleStyle = {
      color: '#53585f',
      textAlign: 'left',
      fontSize: 28,
      fontWeight: 100
    };

    let toolbarStyle = {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
      flexDirection: 'row',
      height: 80,
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 10,
    };

    let toolbarGlyphStyle = {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      flexDirection: 'row',
      height: 80,
      padding: 0,
      margin: 0
    };

    let colBoxStyle = {
      padding: 0,
      marginTop: 20
    }

    let imageStyle = {
      borderRadius: '50%',
      margin: 10,
      height: '30vh',
      width: '30vh',
      objectFit: 'cover'
    };

    let tableContainerStyle = {
      border: 1,
      borderColor: '#afb8c8',
      borderStyle: 'solid',
      backgroundColor: '#f7f6f7'
    };

    let centeredContainerDivStyle = {
      minHeight: 400,
      marginTop: 0,
      backgroundColor: '#f7f6f7',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    };

    let imagePreviewStyle = {
      width: cardHeightStyle,
      height: 'auto',
      backgroundColor: '#ffffff',
      border: 6,
      borderStyle: 'solid',
      borderColor: '#ffffff',
      marginTop: 40,
      marginBottom: 40,
      marginLeft: 40,
      marginRight: 40,
      padding: 0,
      overflow: 'hidden',
      WebkitBoxShadow: '1px 1px 3px 3px #ccc',  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
      MozBoxShadow: '1px 1px 3px 3px #ccc',  /* Firefox 3.5 - 3.6 */
      boxShadow: '1px 1px 3px 3px #ccc',  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
    };

    let imagePreviewContainerDivStyle = {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      flexDirection: 'column',
      height: cardHeightStyle,
      width: cardWidthStyle,
      overflow: 'hidden'
    };


    let dropzoneStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: cardWidthStyle,
      height: cardHeightStyle,
      backgroundColor: '#ffffff',
      padding: 0,
      marginTop: 40,
      marginBottom: 40,
      marginLeft: 40,
      marginRight: 40,
      WebkitBoxShadow: '1px 1px 3px 3px #ccc',  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
      MozBoxShadow: '1px 1px 3px 3px #ccc',  /* Firefox 3.5 - 3.6 */
      boxShadow: '1px 1px 3px 3px #ccc',  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
    };

    let dropzoneActiveStyle = {
      borderStyle: 'solid',
      backgroundColor: '#eee'
    };

    let addContentImgDiv = {
      textAlign: 'center',
      color:'#0c34fb'
    };

    let addContentImageStyle = {
      width: 50,
      paddingTop: 0,
      marginBottom:20
    };

    let addContentInstructionsDiv = {
      color: '#0c34fb',
      fontSize: 16,
    };

    let thumbnailImgStyle = {
      position: 'relative',
      width: cardHeightStyle,
      minHeight: cardHeightStyle,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundImage: "url('" + this.state.loggedInUser.getProfilePhotoUrl() + "')",
      backgroundColor: '#ffffff',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      border: 6,
      borderStyle: 'solid',
      borderColor: '#ffffff'
    };

    let imagePreviewNoMarginStyle = {
      width: 'auto',
      height: cardHeightStyle,
      backgroundColor: '#ffffff',
      margin: 0,
      padding: 0,
      overflow: 'hidden',
      WebkitBoxShadow: '1px 1px 3px 3px #ccc',  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
      MozBoxShadow: '1px 1px 3px 3px #ccc',  /* Firefox 3.5 - 3.6 */
      boxShadow: '1px 1px 3px 3px #ccc',  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
    };

    var topButtonStyle = {
      color: '#53585f',
      border: 0,
      fontSize: 30,
      backgroundColor: '#ffffff',
      padding: 0,
      margin: 0,
    };

    let userNameDivStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      flexDirection: 'column',
      height: '30vh',
      margin: 10,
      padding: 0,
      color: '#53585f',
      fontSize: 20,
      fontWeight: 600
    }

    let buttonContainerStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      flexDirection: 'column',
    }

    let enabledButtonStyle = {
      width: 160,
      paddingTop: 6,
      paddingBottom: 6,
      margin: 0
    }

    let emphasisDivStyle = {
      fontSize: 20,
      fontWeight: 900
    }

    let infoDivStyle = {
      fontSize: 14,
      fontWeight: 400
    }

    return (
      <div>
        <Container>
          <Row style={headerRowStyle}>
            <Col md={10} style={toolbarStyle}>
              <div style={titleStyle}>Advanced User Tools</div>
            </Col>
            <Col md={2} style={toolbarGlyphStyle}>
              <OverlayTrigger container={this} trigger="click" placement="left" overlay={authPopup} rootClose>
                <Button style={topButtonStyle}>
                  <Settings />
                </Button>
              </OverlayTrigger>
            </Col>
          </Row>
          <Row>
            <Col md={4} style={colBoxStyle}>
              <Image style={imageStyle} src={this.state.loggedInUser.getProfilePhotoUrl()} circle />
            </Col>
            <Col md={6} style={colBoxStyle}>
              <div style={userNameDivStyle}>
                <div style={emphasisDivStyle}>
                  {this.state.loggedInUser.firstName} {this.state.loggedInUser.lastName}
                </div>
                <div style={infoDivStyle}>
                  Email: {this.state.loggedInUser.getEmail()}
                </div>
              </div>
            </Col>
            <Col md={2} style={colBoxStyle}>
              <div style={buttonContainerStyle}>
                <Button className="swytchbackActiveButton" style={enabledButtonStyle} onClick={this.openUserInfoModal}>Edit Profile</Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} style={colBoxStyle}>
              <div style={{
                padding: 20,
                marginRight: 0,
                width: '100%',
                borderRadius: '10px',
                WebkitBoxShadow: '1px 1px 3px 3px #ccc',  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
                MozBoxShadow: '1px 1px 3px 3px #ccc',  /* Firefox 3.5 - 3.6 */
                boxShadow: '1px 1px 3px 3px #ccc',  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
              }}>
                <MessagingCampaignAdmin
                  user={this.state.loggedInUser}
                  handleUpdateMessagingCampaignName={this.handleUpdateMessagingCampaignName}
                  handleMessageChangeMethod={this.handleMessageChangeMethod}
                  handlePhoneNumbersChangeMethod={this.handlePhoneNumbersChangeMethod} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} style={colBoxStyle}>
              <div style={{
                padding: 20,
                marginRight: 0,
                width: '100%',
                borderRadius: '10px',
                WebkitBoxShadow: '1px 1px 3px 3px #ccc',  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
                MozBoxShadow: '1px 1px 3px 3px #ccc',  /* Firefox 3.5 - 3.6 */
                boxShadow: '1px 1px 3px 3px #ccc',  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
              }}>
                <SegmentsAdmin user={this.state.loggedInUser}/>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} style={colBoxStyle}>
              <div style={{
                padding: 20,
                width: '100%',
                borderRadius: '10px',
                WebkitBoxShadow: '1px 1px 3px 3px #ccc',  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
                MozBoxShadow: '1px 1px 3px 3px #ccc',  /* Firefox 3.5 - 3.6 */
                boxShadow: '1px 1px 3px 3px #ccc',  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
              }}>
                <FriendsTable rows={rows} enableInvitePeople={this.advancedMode} />
              </div>
            </Col>
          </Row>
        </Container>
        <UserInfoFormModal cardHeight={cardHeight} advancedMode={this.advancedMode} showModal={this.state.showUserInfoModal} onHideAction={this.closeUserInfoModal}/>
        <CredentialsModal showModal={this.state.showSettingsModal} onHideAction={this.closeSettingsModal}/>
      </div>
    );


  }
}

const AdvancedUserToolsWrapper = () => {

  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (destination) => {
    navigate(destination);
  }

  return <AdvancedUserTools navigationHandler={handleNavigation} location={location}/>
}

export default AdvancedUserToolsWrapper;
